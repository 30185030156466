import React, { useState, useEffect } from "react";
import EducationTopBar from "../education/topbar/EducationTopBar";
import WorkshopFooterPage from "../education/Workshops/WorkshopFooter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ContactUs() {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Base styles (mobile)
  const baseStyles = {
    mainContainer: {
      minHeight: "100vh",
      backgroundColor: "#000414",
      backgroundImage:
        "radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px)",
      backgroundSize: "20px 20px",
      color: "#FFFFFF",
      fontFamily: "'Questrial', sans-serif",
      position: "relative",
      overflow: "hidden",
      // padding: "0 15px",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "68vh",
      padding: "1rem",
      marginTop: "60px",
    },
    title: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1.8rem",
      fontWeight: "700",
      background: "linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      letterSpacing: "1px",
      textShadow: "0 0 8px rgba(168, 102, 255, 0.6)",
      textAlign: "center",
      marginBottom: "1.5rem",
    },
    contentContainer: {
      background: "rgba(0, 4, 20, 0.9)",
      borderRadius: "15px",
      padding: "1.5rem",
      border: "1px solid rgba(173, 102, 255, 0.2)",
      textAlign: "center",
      width: "100%",
      maxWidth: "90%",
    },
    subtitle: {
      fontSize: "1rem",
      color: "rgba(255, 255, 255, 0.9)",
      textShadow: "0 0 8px rgba(168, 102, 255, 0.6)",
      margin: "1.5rem 0",
      lineHeight: "1.5",
    },
    contactInfo: {
      fontSize: "1.2rem",
      fontWeight: "600",
      background: "linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textShadow: "0 0 5px rgba(102, 153, 255, 0.5)",
      margin: "0.8rem 0",
    },
  };

  // Tablet styles
  const tabletStyles = {
    ...baseStyles,
    mainContainer: {
      ...baseStyles.mainContainer,
      padding: "0 30px",
    },
    contentWrapper: {
      ...baseStyles.contentWrapper,
      padding: "2rem",
    },
    title: {
      ...baseStyles.title,
      fontSize: "2.5rem",
      marginBottom: "2rem",
    },
    contentContainer: {
      ...baseStyles.contentContainer,
      padding: "2rem",
      borderRadius: "20px",
      maxWidth: "80%",
    },
    subtitle: {
      ...baseStyles.subtitle,
      fontSize: "1.1rem",
      margin: "2rem 0",
    },
    contactInfo: {
      ...baseStyles.contactInfo,
      fontSize: "1.5rem",
    },
  };

  // Desktop styles
  const desktopStyles = {
    ...tabletStyles,
    mainContainer: {
      ...tabletStyles.mainContainer,
      padding: "0",
    },
    contentWrapper: {
      ...tabletStyles.contentWrapper,
      marginTop: "100px",
      padding: "0 5rem",
    },
    title: {
      ...tabletStyles.title,
      fontSize: "3.5rem",
      letterSpacing: "2px",
    },
    contentContainer: {
      ...tabletStyles.contentContainer,
      padding: "2.5rem",
      border: "2px solid rgba(173, 102, 255, 0.2)",
      maxWidth: "600px",
    },
    subtitle: {
      ...tabletStyles.subtitle,
      fontSize: "1.2rem",
      margin: "2.5rem 0",
      lineHeight: "1.6",
    },
    contactInfo: {
      ...tabletStyles.contactInfo,
      fontSize: "1.8rem",
      margin: "1rem 0",
    },
  };

  // Select styles based on screen size
  const styles = isMobile
    ? baseStyles
    : isTablet
    ? tabletStyles
    : desktopStyles;

  return (
    <div style={styles.mainContainer}>
      <EducationTopBar
        logo={
          <div
            onClick={() => history.push("/")}
            style={{ display: "flex", alginItems: "center", cursor: "pointer" }}
          >
            <img
              className={isMobile ? "mobile-logo" : "desktop-logo"}
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
              alt=""
            />
          </div>
        }
      />
      <div style={styles.contentWrapper}>
        <h1 style={styles.title}>Contact Us</h1>
        <div style={styles.contentContainer}>
          <h2 style={styles.subtitle}>
            We are here to assist you with any questions, concerns, or feedback.
            Please feel free to contact us using the details below:
          </h2>
          <h1 style={styles.contactInfo}>Email: acetechies@gmail.com</h1>
          <h1 style={styles.contactInfo}>
            Address: Viman Nagar, Pune, Maharashtra
          </h1>
        </div>
      </div>
      <WorkshopFooterPage />
    </div>
  );
}

export default ContactUs;
