import { Skeleton } from "@mui/material";
import React from "react";

function MySkeletonLoader({ width, height }) {
  return (
    <Skeleton
      width={width || 300}
      height={height || 350}
      animation="wave"
      sx={{
        borderRadius: "12px",
        background:
          "linear-gradient(90deg, #595959 25%, #9e9e9e 50%, #595959 75%)",
        backgroundSize: "200% 100%",
        animation: "shimmer 1.2s infinite",
        "@keyframes shimmer": {
          "0%": { backgroundPosition: "-200% 0" },
          "100%": { backgroundPosition: "200% 0" },
        },
        margin: "0px 20px",
      }}
    />
  );
}

export default MySkeletonLoader;
