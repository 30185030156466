import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import "./LoginScreen.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googleLoginAction, userLoginAction } from "../../actions/userActions"; // Assuming this exists
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.userLogin || {});

  const handleEmailChange = (e) => setEmail(e.target.value.trim());
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Login Attempt:", { email, password });
    // Replace with your actual login action, e.g.:
    dispatch(userLoginAction(email, password));
  };

  const handleRegisterClick = () => history.push("/signup");

  const googleLoginButton = () => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
      <GoogleLogin
        onSuccess={(res) => {
          dispatch(googleLoginAction(res.credential));
          console.log("Google Login Success:", res);
        }}
        onError={() => console.log("Google Login Failed")}
        width="260"
      />
    </GoogleOAuthProvider>
  );

  const formVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <div className="login-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div className="left-panel">
        <motion.div
          className="bootcamp-header"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="bootcamp-title">
            MERN + LLM
            <span className="cursor">|</span>
          </h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="bootcamp-subtitle"
          >
            Full-Stack + AI Bootcamp
          </motion.p>
          <motion.p
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 1 }}
            className="bootcamp-tagline"
          >
            Code the Future
          </motion.p>
        </motion.div>
        <motion.div
          className="bootcamp-details"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          <div className="cards-marquee-wrapper">
            <div className="blur-top"></div>
            <div className="cards-container">
              <div className="cards-column cards-column-left">
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Intensive MERN Training</h3>
                    <p>
                      Master MongoDB, Express, React, and Node.js with hands-on
                      projects.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>LLM API Integration</h3>
                    <p>
                      Learn to integrate AI models like ChatGPT into your
                      applications.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Live Coding Sessions</h3>
                    <p>
                      Code alongside industry mentors in real-time workshops.
                    </p>
                  </motion.div>
                </div>
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Intensive MERN Training</h3>
                    <p>
                      Master MongoDB, Express, React, and Node.js with hands-on
                      projects.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>LLM API Integration</h3>
                    <p>
                      Learn to integrate AI models like ChatGPT into your
                      applications.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Live Coding Sessions</h3>
                    <p>
                      Code alongside industry mentors in real-time workshops.
                    </p>
                  </motion.div>
                </div>
              </div>
              <div className="cards-column cards-column-right">
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Portfolio Projects</h3>
                    <p>Build real-world apps to showcase in your portfolio.</p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Hands-On MERN Stack</h3>
                    <p>
                      Deep dive into full-stack development with practical
                      exercises.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Career Support</h3>
                    <p>
                      Get code reviews and guidance to kickstart your tech
                      career.
                    </p>
                  </motion.div>
                </div>
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Portfolio Projects</h3>
                    <p>Build real-world apps to showcase in your portfolio.</p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Hands-On MERN Stack</h3>
                    <p>
                      Deep dive into full-stack development with practical
                      exercises.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Career Support</h3>
                    <p>
                      Get code reviews and guidance to kickstart your tech
                      career.
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
            <div className="blur-bottom"></div>
          </div>
        </motion.div>
      </div>
      <div className="right-panel">
        <motion.div
          className="mobile-title-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="mobile-title">Welcome Back</h1>
          <p className="mobile-subtitle">
            Login to continue your journey with{" "}
            <span className="hashtag">
              #<span className="gradient-text">AcetechiesAcademy</span>
            </span>
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="form-container"
        >
          <div className="form-header">
            <img
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
              alt="MERN + LLM Bootcamp"
              className="logo"
            />
            <h2>Login to Your Account</h2>
            <p>Access your bootcamp resources</p>
          </div>

          <form className="access-form" onSubmit={handleFormSubmit}>
            <motion.div
              variants={formVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="form-step"
            >
              <div className="data-port">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Your email address"
                  required
                />
              </div>
              <div className="data-port">
                <label>Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Your password"
                  required
                  minLength={8}
                />
              </div>
              {error && (
                <Typography className="error-message">
                  {Array.isArray(error)
                    ? error.map((err) => err.message).join(", ")
                    : error?.data?.message}
                </Typography>
              )}
              <Button
                type="submit"
                className="launch-button"
                disabled={
                  loading || !isValidEmail(email) || password.length < 8
                }
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: "#ad33cc" }} />
                ) : (
                  "Login Now"
                )}
              </Button>
            </motion.div>
          </form>

          <div className="alternate-path">
            <span>OR</span>
          </div>

          <div className="google-portal">
            {loading ? (
              <CircularProgress size={20} className="portal-spinner" />
            ) : (
              googleLoginButton()
            )}
          </div>

          <p className="return-gate">
            Not Enrolled Yet?{" "}
            <Button onClick={handleRegisterClick} className="gate-button">
              Register Here
            </Button>
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default LoginScreen;
