import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import "./Registration.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  googleLoginAction,
  userRegisterAction,
} from "../../actions/userActions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

function RegistrationScreen() {
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [typedText, setTypedText] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.userRegister);

  const fullTitle = "MERN + LLM";

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullTitle.length) {
        setTypedText(fullTitle.slice(0, index + 1));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 150);
    return () => clearInterval(typingInterval);
  }, []);

  const handleFirstNameChange = (e) => setFirstName(e.target.value.trim());
  const handleLastNameChange = (e) => setLastName(e.target.value.trim());
  const handleEmailChange = (e) => setEmail(e.target.value.trim());
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) setPhoneNumber(value);
  };
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);
  const handleLoginClick = () => history.push("/login");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Bootcamp Registration:", {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
    });
    dispatch(
      userRegisterAction(firstName, lastName, email, phoneNumber, password)
    );
  };

  const googleLoginButton = () => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
      <GoogleLogin
        onSuccess={(res) => {
          dispatch(googleLoginAction(res.credential));
          console.log("Google Login Success:", res);
        }}
        onError={() => console.log("Google Login Failed")}
        width="260"
      />
    </GoogleOAuthProvider>
  );

  const formVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <div className="registration-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Signup</title>
      </Helmet>
      <div className="left-panel">
        <motion.div
          className="bootcamp-header"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="bootcamp-title">
            {typedText}
            <span className="cursor">|</span>
          </h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="bootcamp-subtitle"
          >
            Full-Stack + AI Bootcamp
          </motion.p>
          <motion.p
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 1 }}
            className="bootcamp-tagline"
          >
            Code the Future
          </motion.p>
        </motion.div>
        <motion.div
          className="bootcamp-details"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.5 }}
        >
          <div className="cards-marquee-wrapper">
            <div className="blur-top"></div>
            <div className="cards-container">
              <div className="cards-column cards-column-left">
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Intensive MERN Training</h3>
                    <p>
                      Master MongoDB, Express, React, and Node.js with hands-on
                      projects.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>LLM API Integration</h3>
                    <p>
                      Learn to integrate AI models like ChatGPT into your
                      applications.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Live Coding Sessions</h3>
                    <p>
                      Code alongside industry mentors in real-time workshops.
                    </p>
                  </motion.div>
                </div>
                {/* Duplicate for seamless scrolling */}
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Intensive MERN Training</h3>
                    <p>
                      Master MongoDB, Express, React, and Node.js with hands-on
                      projects.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>LLM API Integration</h3>
                    <p>
                      Learn to integrate AI models like ChatGPT into your
                      applications.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Live Coding Sessions</h3>
                    <p>
                      Code alongside industry mentors in real-time workshops.
                    </p>
                  </motion.div>
                </div>
              </div>
              <div className="cards-column cards-column-right">
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Portfolio Projects</h3>
                    <p>Build real-world apps to showcase in your portfolio.</p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Hands-On MERN Stack</h3>
                    <p>
                      Deep dive into full-stack development with practical
                      exercises.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Career Support</h3>
                    <p>
                      Get code reviews and guidance to kickstart your tech
                      career.
                    </p>
                  </motion.div>
                </div>
                {/* Duplicate for seamless scrolling */}
                <div className="cards-group">
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Portfolio Projects</h3>
                    <p>Build real-world apps to showcase in your portfolio.</p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Hands-On MERN Stack</h3>
                    <p>
                      Deep dive into full-stack development with practical
                      exercises.
                    </p>
                  </motion.div>
                  <motion.div className="bootcamp-card" variants={cardVariants}>
                    <h3>Career Support</h3>
                    <p>
                      Get code reviews and guidance to kickstart your tech
                      career.
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
            <div className="blur-bottom"></div>
          </div>
        </motion.div>
      </div>
      <div className="right-panel">
        <motion.div
          className="mobile-title-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="mobile-title">Welcome</h1>
          <p className="mobile-subtitle">
            Get your career started by{" "}
            <span className="hashtag">
              #<span className="gradient-text">AcetechiesAcademy</span>
            </span>
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="form-container"
        >
          <div className="form-header">
            <img
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
              alt="MERN + LLM Bootcamp"
              className="logo"
            />
            <h2>Join Us to achieve your dream</h2>
            <p>Sign up Now for Live Hands on Coding</p>
          </div>

          <form className="access-form" onSubmit={handleFormSubmit}>
            {step === 1 && (
              <motion.div
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="form-step"
              >
                <div className="data-port">
                  <label>First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    placeholder="Your first name"
                    required
                  />
                </div>
                <div className="data-port">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    placeholder="Your last name"
                    required
                  />
                </div>
                <Button
                  className="next-button"
                  onClick={handleNextStep}
                  disabled={!firstName || !lastName}
                >
                  Next
                </Button>
              </motion.div>
            )}

            {step === 2 && (
              <motion.div
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="form-step"
              >
                <div className="data-port">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your email address"
                    required
                  />
                </div>
                <div className="data-port">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Your phone number"
                    required
                    pattern="[0-9]*"
                  />
                </div>
                <div className="button-group">
                  <Button className="prev-button" onClick={handlePrevStep}>
                    Previous
                  </Button>
                  <Button
                    className="next-button"
                    onClick={handleNextStep}
                    disabled={!isValidEmail(email) || phoneNumber.length < 10}
                  >
                    Next
                  </Button>
                </div>
              </motion.div>
            )}

            {step === 3 && (
              <motion.div
                variants={formVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="form-step"
              >
                <div className="data-port">
                  <label>Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Create a password"
                    required
                    minLength={6}
                  />
                </div>
                {error && (
                  <Typography className="error-message">
                    {Array.isArray(error)
                      ? error.map((err) => err.message).join(", ")
                      : error?.data?.message}
                  </Typography>
                )}
                <div className="button-group">
                  <Button className="prev-button" onClick={handlePrevStep}>
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="launch-button"
                    disabled={loading || password.length < 8}
                  >
                    {loading ? <CircularProgress size={20} /> : "Enroll Now"}
                  </Button>
                </div>
              </motion.div>
            )}
          </form>

          <div className="alternate-path">
            <span>OR</span>
          </div>

          <div className="google-portal">
            {loading ? (
              <CircularProgress size={20} className="portal-spinner" />
            ) : (
              googleLoginButton()
            )}
          </div>

          <p className="return-gate">
            Already Enrolled?{" "}
            <Button onClick={handleLoginClick} className="gate-button">
              Login Here
            </Button>
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default RegistrationScreen;
