// DashboardWorkshop.jsx
import React, { useState, useEffect } from "react";
import "./DashboardWorkshop.css";
import { formatDate } from "../../../utils/change_time_format";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import ReadMoreLess from "../reusables/ReadMoreLess";
import { Avatar, Box, Modal, Popover } from "@mui/material";
import { profileImages } from "../../../utils/constants";
import {
  bootcampConfirmTimeSlotAction,
  bootcampPaymentAction,
  getMybootcampPaymentsAction,
  getRegisteredBootCampsAction,
} from "../../../actions/BootCamp/bootcampActions";
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import TimeSlotConfirmPopup from "./TimeSlotConfirmPopup";
import GoogleMeetIcon from "@mui/icons-material/Videocam";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EventIcon from "@mui/icons-material/Event";
import {
  generateMerchantUserId,
  generateTransactionId,
} from "../../../utils/payment_utils";
import { userLogoutAction } from "../../../actions/userActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MySkeletonLoader from "../reusables/MySkeletonLoader";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";

const BootcampCard = ({ item, onClick }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const media = item?.media?.length > 0 ? item?.media : null;

  const startDate = new Date(
    item?.dates?.startDate ?? "2025-03-02T00:00:00.000Z"
  );
  var formattedStartDate = formatDate(startDate);

  // Calculate current day offset relative to startDate
  const currentDate = new Date();
  const date = formatDate(item?.dates?.startDate ?? "2025-03-02T00:00:00.000Z");
  const timeDiff = currentDate.getTime() - startDate.getTime();
  const dayOffset = Math.floor(timeDiff / (1000 * 3600 * 24)); // Days difference
  const currentBootcampDay = dayOffset >= 0 ? dayOffset + 1 : 1;
  const dateToDisplay = dayOffset >= 0 ? currentDate : startDate;
  formattedStartDate = formatDate(dateToDisplay);
  const alreadyRegistered = item?.registeredUsers?.includes(userInfo?._id);
  const isPaidUser = item?.paidUsers?.includes(userInfo?._id);
  const hasUserSelectedTimeSlot = item?.timeSlots?.find((slot) =>
    slot.registeredUsers.includes(userInfo?._id)
  );
  var timeSlotDetails = item?.timeSlots?.find((slot) =>
    slot?.registeredUsers?.includes(userInfo?._id)
  );
  const { confirmTimeSlot } = useSelector(
    (state) => state.confirmTimeSlotStore
  );
  if (
    timeSlotDetails === undefined ||
    (timeSlotDetails === null && confirmTimeSlot?.timeSlot)
  ) {
    timeSlotDetails = {
      title: confirmTimeSlot?.timeSlot?.title,
      startTime: confirmTimeSlot?.timeSlot?.startTime,
      endTime: confirmTimeSlot?.timeSlot?.endTime,
    };
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const googleMeetLink =
    item?.meetLink?.length > 0
      ? item?.meetLink
      : "https://meet.google.com/abc-defg-hij";

  const handleCopyLink = () => {
    navigator.clipboard.writeText(googleMeetLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleJoinClick = () => {
    window.open(googleMeetLink, "_blank");
  };

  const handleLiveButtonClick = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const sessionDetails = {
    capacity: item?.capacity || 35,
    endTime: timeSlotDetails?.endTime || "12:30 PM",
    registeredUsers: item?.registeredUsers || ["66067ac230d0a9ac1e70692e"],
    startTime: timeSlotDetails?.startTime || "10:00 AM",
    title: item?.title || "Morning Theory Session",
    _id: item?._id || "67c35eaaa5bc60b7b4d17b18",
  };

  const isSessionLive = () => {
    if (
      !timeSlotDetails ||
      !timeSlotDetails.startTime ||
      !timeSlotDetails.endTime ||
      !item?.dates?.startDate ||
      !item?.dates?.endDate
    ) {
      return false;
    }
    const now = new Date();

    // Parse startTime
    const startMatch = timeSlotDetails.startTime.match(
      /(\d+):(\d+)\s*(AM|PM)/i
    );
    if (!startMatch) return false;
    const [, startHours, startMinutes, startPeriod] = startMatch;
    let startHour = parseInt(startHours);
    const startMinute = parseInt(startMinutes);
    if (startPeriod.toUpperCase() === "PM" && startHour !== 12) startHour += 12;
    if (startPeriod.toUpperCase() === "AM" && startHour === 12) startHour = 0;

    // Parse endTime
    const endMatch = timeSlotDetails.endTime.match(/(\d+):(\d+)\s*(AM|PM)/i);
    if (!endMatch) return false;
    const [, endHours, endMinutes, endPeriod] = endMatch;
    let endHour = parseInt(endHours);
    const endMinute = parseInt(endMinutes);
    if (endPeriod.toUpperCase() === "PM" && endHour !== 12) endHour += 12;
    if (endPeriod.toUpperCase() === "AM" && endHour === 12) endHour = 0;

    // Define session date range
    const sessionStartDate = new Date(item.dates.startDate);
    sessionStartDate.setHours(0, 0, 0, 0); // Start of first day

    const sessionEndDate = new Date(item.dates.endDate);
    sessionEndDate.setHours(23, 59, 59, 999); // End of last day

    // Check if now is within the date range
    const nowDateOnly = new Date(now);
    nowDateOnly.setHours(0, 0, 0, 0);
    if (nowDateOnly < sessionStartDate || nowDateOnly > sessionEndDate) {
      return false;
    }

    // Apply daily time slot to current day
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    const dailyStart = new Date(today);
    dailyStart.setHours(startHour, startMinute, 0, 0);

    const dailyEnd = new Date(today);
    dailyEnd.setHours(endHour, endMinute, 0, 0);

    if (dailyEnd <= dailyStart) {
      dailyEnd.setDate(dailyEnd.getDate() + 1);
    }

    return now >= dailyStart && now <= dailyEnd;
  };

  const liveStatus = isSessionLive();
  const buttonConfig = getButtonConfig(
    isPaidUser,
    alreadyRegistered,
    hasUserSelectedTimeSlot,
    timeSlotDetails
  );
  // Check if the bootcamp is in the past
  // const currentDate = new Date();
  const endDate = new Date(item?.dates?.endDate);
  const isPastBootcamp = endDate < currentDate;
  const isDay1Free = item?.daywiseDetails?.[0]?.isFree || false;
  const currentDayIndex = item?.daywiseDetails?.findIndex((day) => {
    const dayDate = new Date(item.dates.startDate);
    dayDate.setDate(dayDate.getDate() + item.daywiseDetails.indexOf(day));
    return dayDate.toDateString() === new Date().toDateString();
  });
  const isBeyondDay1 = currentDayIndex > 0;

  const renderModalContent = () => {
    if (isDay1Free && isBeyondDay1 && !isPaidUser) {
      // Show payment prompt for non-paid users on Day 2+ after free Day 1
      return (
        <div className="ai-modal-payment-prompt">
          <Typography
            variant="h6"
            sx={{ color: "#fff", mb: 2, fontWeight: "bold" }}
          >
            Continue Your Learning Journey
          </Typography>
          <Typography sx={{ color: "#d6deeb", mb: 2 }}>
            You’ve enjoyed Day 1 for free! To access{" "}
            <strong> All Upcoming Days</strong>, unlock the full bootcamp with a
            one-time payment.
          </Typography>
          <Button
            variant="contained"
            className="workshop-button purple animated-pay-button"
            onClick={() =>
              dispatch(
                bootcampPaymentAction(
                  generateTransactionId(),
                  generateMerchantUserId(userInfo?._id),
                  item?.price?.discountPrice,
                  null,
                  item?._id
                )
              )
            }
          >
            Unlock All Days for ₹{item?.price?.discountPrice}
          </Button>
          <Typography className="original-price" sx={{ mt: 1 }}>
            <s>₹{item?.price?.originalPrice}</s> - One-time fee for full access!
          </Typography>
        </div>
      );
    } else {
      // Default content for Day 1 or non-paid users before Day 2
      return (
        <>
          <div className="ai-modal-details">
            <div className="time-info">
              <div
                style={{
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CalendarMonthIcon
                  sx={{ fontSize: 18, mr: 1, color: "#4CAF50" }}
                />
                <span>
                  {formattedStartDate?.day} {formattedStartDate?.month},{" "}
                  {formattedStartDate?.year}
                </span>
              </div>
              <AccessTimeIcon sx={{ fontSize: 18, mr: 1, color: "#4CAF50" }} />
              <span>
                {sessionDetails.startTime} - {sessionDetails.endTime}
              </span>
            </div>
            <div className="activation-message">
              <span style={{ fontSize: "12px" }}>
                {liveStatus
                  ? "Link is Activated On:"
                  : "Link will be activated on:"}
              </span>
              <span style={{ fontWeight: "900" }}>
                {formattedStartDate?.day} {formattedStartDate?.month},{" "}
                {formattedStartDate?.year} at {sessionDetails.startTime}
              </span>
            </div>
            <div className="ai-modal-link-container">
              <GoogleMeetIcon sx={{ fontSize: 20, mr: 1, color: "#4285F4" }} />
              {liveStatus ? (
                <a
                  href={googleMeetLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ai-modal-link"
                  onClick={(e) => !liveStatus && e.preventDefault()}
                >
                  {googleMeetLink}
                </a>
              ) : (
                <span className="ai-modal-link blurred-link">
                  Link available when live
                </span>
              )}
              {liveStatus && (
                <IconButton
                  onClick={handleCopyLink}
                  sx={{ ml: 1 }}
                  aria-label="Copy link"
                >
                  {isCopied ? (
                    <CheckIcon sx={{ color: "#4CAF50" }} />
                  ) : (
                    <ContentCopyIcon sx={{ color: "#b0b0cc" }} />
                  )}
                </IconButton>
              )}
            </div>
          </div>
          <button
            className="ai-modal-join-btn"
            onClick={handleJoinClick}
            disabled={!liveStatus}
          >
            Join Live Session
          </button>
        </>
      );
    }
  };

  return (
    <div
      onClick={() => onClick(item)}
      key={item?._id}
      className={`workshop-card ${isPaidUser ? "premium-card" : ""}`}
    >
      <div className="card-image-container">
        {media?.length > 0 && media[0]?.type === "video" ? (
          <div className="card-workshop-youtube-container">
            <YouTube
              videoId={media[0]?.url}
              opts={{
                playerVars: {
                  autoplay: 0,
                },
              }}
            />
          </div>
        ) : (
          <img
            width={320}
            height={200}
            src={
              (item?.media?.length > 0 && item?.media[0]?.url) ||
              "https://plus.unsplash.com/premium_photo-1677094310919-d0361465d3be?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt=""
            className="workshop-image"
          />
        )}
        <div className="date-overlay">
          <CalendarMonthIcon className="calendar-icon" />
          <span className="date-text">
            {date?.day} {date?.month}, {date?.year}
          </span>
        </div>
        {alreadyRegistered && (
          <div
            className={`enrolled-badge ${isPaidUser ? "premium-badge" : ""}`}
          >
            <CheckCircleIcon sx={{ fontSize: 16, marginRight: "4px" }} />
            {isPaidUser ? "Premium" : "Enrolled"}
          </div>
        )}
      </div>
      <div className="card-header">
        <div className="level-tag">{item?.level}</div>
        <div className="spots-info">
          <GroupsIcon className="groups-icon" />
          <span className="spots-text">
            {item?.spots?.available} Spots Left
          </span>
        </div>
      </div>
      <h3 className="workshop-title">{item?.title}</h3>
      <p className="workshop-desc">
        <ReadMoreLess color={"#fff"} text={item?.description} maxLength={60} />
      </p>
      <div className="registered-preview">
        {profileImages.slice(0, 3).map((item, idx) => (
          <Avatar
            key={idx}
            sx={{ width: "25px", height: "25px" }}
            src={item}
            className="avatar-overlap"
          />
        ))}
        <span className="registered-count">
          +
          {item?.registeredUsers?.length >= 20
            ? item?.registeredUsers?.length
            : 20}{" "}
          Enrolled
        </span>
      </div>
      <div className="card-footer">
        {isPastBootcamp ? (
          <button className="completed-btn full-width" disabled>
            <CheckCircleIcon sx={{ fontSize: 16, marginRight: "4px" }} />
            Completed
          </button>
        ) : alreadyRegistered ? (
          <button
            className={buttonConfig.className}
            onClick={() => onClick(item)}
          >
            {isPaidUser && (
              <img
                style={{ width: "15px", height: "15px", marginRight: "5px" }}
                src="images/lock_open_black_icon.png"
                alt="open lock icon"
              />
            )}
            {buttonConfig.text}
          </button>
        ) : (
          <button className="select-time-btn" onClick={() => onClick(item)}>
            Select Time Slot
          </button>
        )}
        {!isPastBootcamp &&
          (hasUserSelectedTimeSlot || timeSlotDetails?.startTime) && (
            <button
              className="live-session-btn"
              onClick={handleLiveButtonClick}
            >
              <span className="live-dot" />
              Join Now
            </button>
          )}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="live-session-modal-title"
        sx={{
          "& .MuiBackdrop-root": {
            backdropFilter: "blur(3px)",
            background: "rgba(0, 0, 0, 0.7)",
          },
        }}
      >
        <div onClick={(e) => e.stopPropagation()} className="ai-modal">
          <div className="ai-modal-header">
            <span className="ai-modal-title">
              {isDay1Free && isBeyondDay1
                ? item?.title
                : timeSlotDetails?.title || "Selected Session"}
            </span>
            <IconButton
              className="ai-modal-close"
              onClick={() => setModalOpen(false)}
            >
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
          </div>
          <div className="ai-modal-content">{renderModalContent()}</div>
        </div>
      </Modal>
    </div>
  );
};

const getButtonConfig = (
  isPaidUser,
  alreadyRegistered,
  hasUserSelectedTimeSlot,
  timeSlotDetails
) => {
  if (isPaidUser) {
    return {
      text: "All Unlocked",
      className: "book-time-btn full-width unlocked", // Optional: Add a special class for styling
    };
  }

  if (alreadyRegistered) {
    if (hasUserSelectedTimeSlot || timeSlotDetails?.startTime) {
      return {
        text: "View Details",
        className: "book-time-btn full-width",
      };
    }
    return {
      text: "Book Your Time Slot",
      className: "book-time-btn full-width",
    };
  }

  // Default case (if not registered and not a paid user)
  return {
    text: "Book Your Time Slot",
    className: "book-time-btn full-width",
  };
};

const DashboardWorkshop = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { registeredBootCamps, loading: loadingRegisteredBootcamps } =
    useSelector((state) => state.getRegisteredBootCampsStore);
  const { loading: loadingTimeSlot, confirmTimeSlot } = useSelector(
    (state) => state.confirmTimeSlotStore
  );
  const { loading: loadingMyPaymentsData, myPaymentsData } = useSelector(
    (state) => state.bootcampGetMyPaymentsStore
  );

  const { userInfo } = useSelector((state) => state.userLogin);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const [activeView, setActiveView] = useState("bootcamps");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleConfirm = async () => {};

  // Open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getRegisteredBootCampsAction());
  }, [dispatch]);

  useEffect(() => {
    if (loadingTimeSlot) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [loadingTimeSlot]);

  const handlePayment = (bootcamp) => {
    setSelectedBootcamp((prev) => ({ ...prev, paid: true }));
    dispatch(
      bootcampPaymentAction(
        generateTransactionId(),
        generateMerchantUserId(userInfo?._id),
        bootcamp?.price?.discountPrice,
        null,
        bootcamp?._id
      )
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedTimeSlot("");
  };

  const renderDayDetails = (bootcamp) => {
    const isPaidUser = bootcamp?.paidUsers?.includes(userInfo?._id);

    return bootcamp.daywiseDetails.map((item, index) => {
      const dayNumber = index + 1;
      const requiresPayment = dayNumber > 1 && !isPaidUser;
      const isCurrentDay = dayNumber === new Date().getDate();
      const isFree = item?.isFree;

      return (
        <Accordion
          inert
          key={index}
          className="workshop-accordion"
          sx={{ backgroundColor: "#1a202c" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#d6deeb" }} />}
            className={isPaidUser || isFree ? "premium-unlocked" : ""}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {isPaidUser || isFree ? (
                <img
                  src="/images/lock_open_icon.png"
                  alt="Premium Unlocked"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <EventIcon sx={{ color: "#b0b0cc" }} />
              )}
              <Typography variant="h6">
                Day {dayNumber} {isCurrentDay && "(Today)"}
              </Typography>
            </Box>
            <Typography
              sx={{
                ml: 2,
                color: isPaidUser || isFree ? "#FFD700" : "#95a5a6",
                fontSize: "0.9rem",
              }}
            >
              {isPaidUser || isFree ? "Unlocked" : "Details"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {item.title}
            </Typography>
            <ul className="learning-list">
              {item.description.map((desc, i) => (
                <li key={i}>{desc}</li>
              ))}
            </ul>
            {requiresPayment ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                className="unlock-message"
              >
                <Typography
                  sx={{ mb: 1, fontWeight: "bold", color: "#ffffff" }}
                >
                  Unlock ALL Days with a One-Time Payment!
                </Typography>
                <Typography
                  sx={{ mb: 1, color: "#d6deeb", fontSize: "0.9rem" }}
                >
                  Pay just once to access all upcoming days of this bootcamp –
                  no per-day fees!
                </Typography>
                <Button
                  variant="contained"
                  className="workshop-button purple animated-pay-button"
                  onClick={() => handlePayment(bootcamp)}
                >
                  Pay ₹{bootcamp.price.discountPrice} (One-Time)
                </Button>
                <Typography className="original-price">
                  <s>₹{bootcamp.price.originalPrice}</s> - One-time payment
                  only!
                </Typography>
              </Box>
            ) : (
              isCurrentDay && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    className="workshop-button purple"
                    href={bootcamp.meetLink || "#"}
                    target="_blank"
                  >
                    Join Session
                  </Button>
                  <Button
                    variant="outlined"
                    className="workshop-button"
                    onClick={() =>
                      navigator.clipboard.writeText(bootcamp.meetLink || "#")
                    }
                  >
                    Copy Link
                  </Button>
                </Box>
              )
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  const selectedTimeSlotBox = (timeSlotId, showButtons) => {
    var timeSlotDetails = selectedBootcamp.timeSlots.find(
      (slot) => slot._id === timeSlotId
    );
    if (
      timeSlotDetails === undefined ||
      (timeSlotDetails === null && confirmTimeSlot?.timeSlot)
    ) {
      timeSlotDetails = {
        title: confirmTimeSlot?.timeSlot?.title,
        startTime: confirmTimeSlot?.timeSlot?.startTime,
        endTime: confirmTimeSlot?.timeSlot?.endTime,
      };
    }
    return (
      <Box className="selected-time-slot">
        <CheckCircleIcon sx={{ color: "rgb(96, 64, 238)", mr: 1 }} />
        <Typography
          component="span"
          sx={{
            color: "#ffffff",
            fontWeight: 500,
            fontSize: "0.95rem",
          }}
        >
          {timeSlotDetails?.title} ({timeSlotDetails?.startTime} -{" "}
          {timeSlotDetails?.endTime})
          {showButtons && (
            <div style={{ display: "flex", gap: "10px", margin: "10px 0px" }}>
              <Button
                onClick={() =>
                  dispatch(
                    bootcampConfirmTimeSlotAction(
                      selectedBootcamp?._id,
                      selectedTimeSlot
                    )
                  )
                }
                className="confirm"
              >
                Confirm
              </Button>
              <Button
                onClick={() => setSelectedTimeSlot("")}
                className="cancel"
              >
                Cancel
              </Button>
            </div>
          )}
        </Typography>
      </Box>
    );
  };

  const renderDrawerContent = (bootcamp) => {
    const hasUserSelectedTimeSlot = selectedBootcamp?.timeSlots?.find((slot) =>
      slot.registeredUsers.includes(userInfo?._id)
    );

    return (
      <Box
        sx={{
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            mb: 2,
            fontWeight: "bold",
            fontFamily: "Questrial",
            marginBottom: "20px",
            padding: "0px 10px",
            marginRight: "10px",
          }}
        >
          {selectedBootcamp?.title}
        </Typography>
        <IconButton
          onClick={() => setIsDrawerOpen(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#d6deeb",
          }}
        >
          <CloseIcon />
        </IconButton>

        {confirmTimeSlot?.timeSlot !== undefined || hasUserSelectedTimeSlot ? (
          <div>{selectedTimeSlotBox(hasUserSelectedTimeSlot?._id, false)}</div>
        ) : (
          <div>
            <FormControl fullWidth className="workshop-form-control">
              <InputLabel>Select Time Slot</InputLabel>
              <Select
                value={selectedTimeSlot}
                onChange={handleTimeSlotChange}
                label="Select Time Slot"
                renderValue={(selected) =>
                  selected
                    ? `${
                        selectedBootcamp.timeSlots.find(
                          (slot) => slot._id === selected
                        )?.title
                      } (${
                        selectedBootcamp.timeSlots.find(
                          (slot) => slot._id === selected
                        )?.startTime
                      } - ${
                        selectedBootcamp.timeSlots.find(
                          (slot) => slot._id === selected
                        )?.endTime
                      })`
                    : "Choose a slot"
                }
              >
                {selectedBootcamp?.timeSlots.map((slot) => (
                  <MenuItem key={slot._id} value={slot._id}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>{slot.title}</Typography>
                      <Typography
                        sx={{ fontSize: "0.85rem", color: "#95a5a6" }}
                      >
                        {slot.startTime} - {slot.endTime} (
                        {slot.capacity - slot.registeredUsers.length} spots
                        left)
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedTimeSlot && selectedTimeSlotBox(selectedTimeSlot, true)}
          </div>
        )}

        {renderDayDetails(selectedBootcamp)}
      </Box>
    );
  };
  const renderContent = () => {
    switch (activeView) {
      case "bootcamps":
        return (
          <div className="bootcamp-sections">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              className="workshop-tabs"
            >
              <Tab label="Upcoming" />
              <Tab label="Next" />
              <Tab label="Past" />
            </Tabs>
            <div className="bootcamp-grid">
              {tabValue === 0 &&
                (registeredBootCamps?.upcomingRegistered?.length > 0 ? (
                  registeredBootCamps.upcomingRegistered.map((bootcamp) => (
                    <BootcampCard
                      key={bootcamp?._id}
                      item={bootcamp}
                      onClick={() => {
                        setSelectedBootcamp(bootcamp);
                        setIsDrawerOpen(true);
                      }}
                    />
                  ))
                ) : loadingRegisteredBootcamps ? (
                  [1, 2, 3].map((i) => (
                    <MySkeletonLoader
                      key={i}
                      width={"300px"}
                      height={"400px"}
                    />
                  ))
                ) : (
                  <div className="no-bootcamps">No upcoming bootcamps.</div>
                ))}
              {tabValue === 1 &&
                (registeredBootCamps?.nextRegistered?.length > 0 ? (
                  registeredBootCamps.nextRegistered.map((bootcamp) => (
                    <BootcampCard
                      key={bootcamp?._id}
                      item={bootcamp}
                      onClick={() => {
                        setSelectedBootcamp(bootcamp);
                        setIsDrawerOpen(true);
                      }}
                    />
                  ))
                ) : (
                  <div className="no-bootcamps">No next bootcamps.</div>
                ))}
              {tabValue === 2 &&
                (registeredBootCamps?.pastRegistered?.length > 0 ? (
                  registeredBootCamps.pastRegistered.map((bootcamp) => (
                    <BootcampCard
                      key={bootcamp?._id}
                      item={bootcamp}
                      onClick={() => {
                        setSelectedBootcamp(bootcamp);
                        setIsDrawerOpen(true);
                      }}
                    />
                  ))
                ) : (
                  <div className="no-bootcamps">No past bootcamps.</div>
                ))}
            </div>
          </div>
        );
      case "profile":
        return (
          <div className="panel">
            <h2>Your Profile</h2>
            <div className="profile-content">
              <div className="info-grid">
                <div>
                  <strong>Name:</strong> {userInfo?.firstName}{" "}
                  {userInfo?.lastName}
                </div>
                <div>
                  <strong>Email:</strong> {userInfo?.email}
                </div>
                <div>
                  <strong>Enrolled Bootcamps:</strong>{" "}
                  {registeredBootCamps?.totalRegistered}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="profile-button purple"
                  disabled
                >
                  Edit Profile
                </Button>
              </div>
            </div>
          </div>
        );
      case "support":
        return (
          <div className="panel">
            <h2>Support</h2>
            <div className="support-content">
              <p className="support-text">
                Need help with your learning journey?
              </p>
              <p className="support-contact">
                Reach out to us at{" "}
                <a href="mailto:acetechies@gmail.com" className="email-link">
                  acetechies@gmail.com
                </a>
              </p>
            </div>
          </div>
        );
      case "payments":
        return (
          <div className="payment-container">
            <div className="payment-header">
              <h2>Payment History</h2>
              <p>View your bootcamp payment records</p>
            </div>

            {loadingMyPaymentsData ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: `${isMobile ? "column" : "row"}`,
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {[1, 2, 3].map((i) => (
                  <MySkeletonLoader key={i} width={"300px"} height={"400px"} />
                ))}
              </div>
            ) : myPaymentsData?.data?.length > 0 ? (
              <div className="payment-list">
                {myPaymentsData.data.map((payment, index) => {
                  const date = formatDate(
                    payment?.createdAt ?? "2025-03-02T00:00:00.000Z"
                  );
                  return (
                    <div key={payment._id || index} className="payment-card">
                      <div className="payment-card-header">
                        <div className="payment-title">
                          <h3>{payment?.title}</h3>
                          <span
                            className={`status-badge ${payment?.status?.toLowerCase()}`}
                          >
                            {payment?.status}
                          </span>
                        </div>
                        <div className="payment-date">
                          {date?.day} {date?.month}, {date?.year}
                        </div>
                      </div>

                      <div className="payment-details">
                        <div className="detail-item">
                          <span className="detail-label">Amount Paid:</span>
                          <span className="detail-value">
                            ₹{payment?.amount}
                          </span>
                        </div>

                        <div className="detail-item">
                          <span className="detail-label">Transaction ID:</span>
                          <span className="detail-value">
                            {payment.transactionId}
                          </span>
                        </div>
                      </div>

                      <div className="payment-media">
                        {payment?.bootcampId &&
                          payment?.bootcampId?.media?.length > 0 && (
                            <div className="media-preview">
                              <img
                                src={payment.bootcampId.media[0].url}
                                alt={
                                  payment.bootcampId.media[0].caption ||
                                  payment.title
                                }
                                className="payment-image"
                              />
                              <div className="media-info">
                                <h4 className="media-title">
                                  {payment?.bootcampId?.title}
                                </h4>
                                <p className="media-description">
                                  {payment?.bootcampId?.description}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>

                      <button
                        className="invoice-button"
                        onClick={() => generateInvoice(payment)}
                      >
                        Download Invoice
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-payments">
                <h3>No Payments Yet</h3>
                <p>Enroll in a bootcamp to see your payment history here!</p>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  // Function to generate PDF invoice
  const generateInvoice = (payment) => {
    const doc = new jsPDF();
    const rupeeSymbol = "rs";
    doc.addFileToVFS("NotoSans-Regular.ttf");
    // Colors
    const primaryColor = [96, 64, 238]; // Purple
    const grayColor = [150, 150, 150];
    const blackColor = [0, 0, 0];

    // Helper function to format date
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    // Header
    doc.setFontSize(24);
    doc.setTextColor(...primaryColor);
    doc.setFont("helvetica", "bold");
    doc.text("INVOICE", 20, 20);

    doc.setFontSize(18);
    doc.setTextColor(...blackColor);
    doc.setFont("helvetica", "bold");
    doc.text("Acetechies Academy", 20, 35);

    // Company Info
    doc.setFontSize(12);
    doc.setTextColor(...blackColor);
    doc.setFont("helvetica", "normal");
    doc.text("Email us: ", 20, 45); // Added "Email us" text
    doc.text("acetechiesacademy@gmail.com", 20, 50); // Email on next line

    // User/Billing Info
    doc.setFontSize(10);
    doc.text("Billed To:", 140, 35);
    doc.text(`User ID: UID-${payment?.userId || "N/A"}`, 140, 42); // Combined and constrained
    doc.text(`Invoice Date: ${formatDate(new Date())}`, 140, 49);
    doc.text(`Transaction ID: ${payment?.transactionId || "N/A"}`, 140, 56);

    // Line separator
    doc.setDrawColor(...grayColor);
    doc.setLineWidth(0.5);
    doc.line(20, 65, 190, 65);

    // Bootcamp Details Section
    doc.setFontSize(14);
    doc.setTextColor(...primaryColor);
    doc.text("Bootcamp Purchase Details", 20, 75);

    // Table for payment/bootcamp details
    const tableData = [
      [
        "Bootcamp Title",
        payment?.bootcampId?.title || "N/A",
        `${payment?.amount || 0}.0 ${rupeeSymbol}`, // Simple ₹ symbol
      ],
      ["Description", payment?.bootcampId?.description || "N/A", ""],
      ["Purchase Date", formatDate(payment?.createdAt), ""],
      [
        "Status",
        payment?.status
          ? payment.status.charAt(0).toUpperCase() + payment.status.slice(1)
          : "N/A",
        "",
      ],
    ];

    autoTable(doc, {
      startY: 80,
      head: [["Item", "Description", "Amount"]],
      body: tableData,
      styles: {
        fontSize: 10,
        cellPadding: 2,
        textColor: blackColor,
      },
      headStyles: {
        fillColor: primaryColor,
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 100 },
        2: { cellWidth: 30, halign: "right" },
      },
    });

    // Total Amount
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.setTextColor(...blackColor);
    doc.text("Total Paid:", payment?.amount > 100 ? 132 : 138, finalY);
    doc.setFont("helvetica", "bold");
    doc.text(`${payment?.amount || 0}.0 ${rupeeSymbol}`, 170, finalY, {
      align: "right",
    }); // Simple ₹ symbol

    // Footer
    doc.setFontSize(10);
    doc.setTextColor(...grayColor);
    doc.setFont("helvetica", "normal");
    const footerY = 270;
    doc.line(20, footerY - 5, 190, footerY - 5);
    doc.text("Thank you for your payment!", 20, footerY);
    doc.text(`Generated on: ${formatDate(new Date())}`, 20, footerY + 7);
    doc.text(
      "Contact us at acetechiesacademy@gmail.com for any queries",
      20,
      footerY + 14
    );

    // Save the PDF
    doc.save(
      `Invoice_${payment?.transactionId || "N/A"}_${formatDate(
        payment?.createdAt || new Date()
      )}.pdf`
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;
  const firstName = userInfo?.firstName?.split(" ")[0] || "User";

  return (
    <div className="dashboard-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
      </Helmet>
      <header className="top-bar">
        <div className="top-bar-left">
          <button
            className="mobile-menu-btn"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            ☰
          </button>
          <div onClick={() => history.push("/")} className="company-branding">
            <img
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png" // Replace with actual logo URL
              alt="Company Logo"
              className="company-logo"
            />
            <span className="company-name">cetechies Academy</span>{" "}
            {/* Example company name */}
          </div>
        </div>
        <div className="top-bar-right">
          <div className="user-profile" onClick={handleUserClick}>
            <Avatar
              sx={{ width: 32, height: 32, bgcolor: "rgb(96, 64, 238)" }}
              className="user-avatar"
            >
              {firstName[0]} {/* Display first letter of first name */}
            </Avatar>
            <span className="user-name desktop-only">{firstName}</span>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleUserMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              className: "user-menu-popover",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Button
                fullWidth
                onClick={() => {
                  setActiveView("bootcamps");
                  handleUserMenuClose();
                }}
                className="menu-item"
              >
                Bootcamps
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setActiveView("profile");
                  handleUserMenuClose();
                }}
                className="menu-item"
              >
                Profile
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setActiveView("support");
                  handleUserMenuClose();
                }}
                className="menu-item"
              >
                Support
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  handleUserMenuClose();
                  history.push("/");
                }}
                className="menu-item"
              >
                Go to Home
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  dispatch(userLogoutAction());
                  setAnchorEl(null);
                  setTimeout(() => {
                    history.push("/");
                  }, 500);
                }} // Add logout logic here if needed
                className="menu-item logout"
              >
                Logout
              </Button>
            </Box>
          </Popover>
        </div>
      </header>

      <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-header">
          <button className="close-btn" onClick={() => setIsSidebarOpen(false)}>
            ×
          </button>
        </div>
        <nav>
          {["bootcamps", "payments", "profile", "support"].map((view) => (
            <button
              key={view}
              className={`nav-item ${activeView === view ? "active" : ""}`}
              onClick={() => {
                console.log("Clicked:", view);
                if (view === "payments") {
                  dispatch(getMybootcampPaymentsAction());
                }
                setActiveView(view);
                setIsSidebarOpen(false);
              }}
            >
              {view.charAt(0).toUpperCase() + view.slice(1)}
            </button>
          ))}
        </nav>
      </aside>

      <main className="main-content">{renderContent()}</main>

      <Drawer
        anchor={window.innerWidth <= 480 ? "bottom" : "right"}
        open={isDrawerOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return; // Prevent closing when clicking outside
          }
          setIsDrawerOpen(false);
        }}
        className="workshop-drawer"
      >
        {selectedBootcamp && renderDrawerContent(selectedBootcamp)}
      </Drawer>

      <TimeSlotConfirmPopup
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default DashboardWorkshop;
