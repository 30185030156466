import React, { useEffect, useState } from "react";
import "./MarqueeScroll.css";
import { Avatar, Rating } from "@mui/material";

const MarqueeScroll = ({
  content = [],
  direction = "left",
  speed = 50,
  isReview = false,
  width = "100%",
  height = "100%",
  objectFit = "contain",
  margin = "0px 80px",
}) => {
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    setContentList([...content, ...content]);
  }, [content]);

  return (
    <div className="marquee-container">
      <div
        className={`marquee-content ${direction}`}
        style={{ animationDuration: `${speed}s` }}
      >
        {contentList.map((item, index) =>
          isReview ? (
            <div key={index} className="review_card_container">
              <div className="reviews_container">
                <p
                  style={{ fontSize: "16px", color: "#222", fontWeight: "600" }}
                >
                  {item?.review}
                </p>
                <div>
                  <Rating name="read-only" value={item?.stars} readOnly />
                  <h3 style={{ margin: "0px" }}>{item?.stars}/5</h3>
                </div>
              </div>
              <div>
                <Avatar src={item?.profileImage} />
                <h4>{item?.fullName}</h4>
              </div>
            </div>
          ) : (
            <div
              className="marquee-item"
              key={index}
              style={{ margin: margin }}
            >
              <img
                src={item?.src}
                alt=""
                style={{ objectFit: objectFit, width: width, height: height }}
              />
              <h1 style={{ color: "#fff" }}>{item?.name}</h1>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MarqueeScroll;
