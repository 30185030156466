import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/system";

// New "breathing dots" animation
const breathe = keyframes`
  0% { transform: scale(0.8); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(0.8); opacity: 0.5; }
`;

// Styled loader component
const BreathingDotsLoader = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  height: 50,
  "& .dot": {
    width: 12,
    height: 12,
    background: "#bb86fc", // Soft purple
    borderRadius: "50%",
    animation: `${breathe} 1.5s infinite ease-in-out`,
    boxShadow: "0 0 8px rgba(187, 134, 252, 0.5)",
  },
  "& .dot:nth-child(2)": {
    animationDelay: "0.2s",
  },
  "& .dot:nth-child(3)": {
    animationDelay: "0.4s",
  },
});

// Styled modal box
const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  background: "#000414",
  borderRadius: 12,
  padding: 24,
  textAlign: "center",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
  border: "1px solid #1a1f2e",
  color: "#ffffff",
});

const TimeSlotConfirmPopup = ({ open, onClose, onConfirm }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (open && !isSuccess) {
      const timer = setTimeout(async () => {
        try {
          await onConfirm();
          setIsSuccess(true);
        } catch (error) {
          console.error("API call failed:", error);
          onClose();
        }
      }, 2000); // Simulate 2s delay
      return () => clearTimeout(timer);
    }
  }, [open, isSuccess, onConfirm, onClose]);

  const handleClose = () => {
    if (!isSuccess) return; // Prevent closing during loading
    setIsSuccess(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="loader-modal-title"
      aria-describedby="loader-modal-description"
      sx={{ backdropFilter: "blur(1px)" }}
    >
      <ModalBox onClick={isSuccess ? handleClose : undefined}>
        {!isSuccess ? (
          <>
            <BreathingDotsLoader>
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </BreathingDotsLoader>
            <Typography
              id="loader-modal-title"
              variant="h6"
              sx={{
                mt: 2,
                color: "#ffffff",
                fontFamily: "Roboto, sans-serif",
                fontWeight: 500,
              }}
            >
              Saving Your Slot...
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleIcon
              sx={{
                fontSize: 50,
                color: "#00C853",
                animation: "scaleIn 0.3s ease-in-out",
                "@keyframes scaleIn": {
                  "0%": { transform: "scale(0)" },
                  "100%": { transform: "scale(1)" },
                },
              }}
            />
            <Typography
              id="loader-modal-title"
              variant="h6"
              sx={{
                mt: 2,
                color: "#ffffff",
                fontFamily: "Roboto, sans-serif",
                fontWeight: 500,
              }}
            >
              Slot Saved Successfully!
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "#ffffff",
                "&:hover": { color: "#00C853" },
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </ModalBox>
    </Modal>
  );
};

export default TimeSlotConfirmPopup;
