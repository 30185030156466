import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./WorkshopFooter.css";

const WorkshopFooterPage = () => {
  const history = useHistory();
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      {/* Header Section */}
      <div className="header-section">
        <h1>AceTechies Academy</h1>
        <p className="paragraph">Feel free to send us your questions</p>
        <div className="button-container">
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=acetechiesacademy@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="send-button"
          >
            Send A Message
          </a>
        </div>
      </div>

      {/* Divider */}
      <hr className="divider" />

      {/* Footer Content */}
      <div className="footer-content">
        <div className="links-section">
          <div className="link-column">
            <p onClick={() => history.push("/")} className="link">
              Home
            </p>
            <p className="link" onClick={() => history.push("terms")}>
              Terms Of Use
            </p>
          </div>
          <div className="link-column">
            <p className="link" onClick={() => history.push("privacy")}>
              Privacy Policy
            </p>
            <p
              className="link"
              onClick={() => history.push("/cancellation-refund")}
            >
              Refund & Cancellation
            </p>
            <p className="link" onClick={() => history.push("contact")}>
              Contact Us
            </p>
          </div>
          <div className="contact-column">
            <h2>Contact</h2>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=acetechiesacademy@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              acetechiesacademy@gmail.com
            </a>
          </div>
        </div>

        {/* Social Icons and Copyright */}
        <div className="social-copyright-section">
          <div className="social-icons">
            <a href="https://www.instagram.com/acetechies.academy/">
              <InstagramIcon className="icon" />
            </a>

            <a href="https://www.linkedin.com/in/acetechies-academy-165b282b3/">
              <LinkedInIcon className="icon" />
            </a>
          </div>
          <div className="copyright-container">
            <p className="copyright">
              Copyright © {currentYear} AceTech Academy. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopFooterPage;
