import React, { useState, useEffect } from "react";
import EducationTopBar from "../education/topbar/EducationTopBar";
import WorkshopFooterPage from "../education/Workshops/WorkshopFooter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function PrivacyPolicy({ policyData }) {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getPoints = () => {
    return (
      <div style={styles.pointsContainer}>
        {policyData.details.map((data, index) => (
          <div key={index} style={styles.policySection}>
            <h2 style={styles.sectionTitle}>{data.title}</h2>
            <ul style={styles.pointsList}>
              {data.points.map((point, idx) => (
                <li key={idx} style={styles.listItem}>
                  <p style={styles.pointText}>{point}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  // Base styles (mobile)
  const baseStyles = {
    mainContainer: {
      minHeight: "100vh",
      backgroundColor: "#000414",
      backgroundImage:
        "radial-gradient(rgba(240, 240, 240, 0.164) 0.4px, transparent 0.8px)",
      backgroundSize: "20px 20px",
      color: "#FFFFFF",
      fontFamily: "'Questrial', sans-serif",
      position: "relative",
      overflow: "hidden",
      padding: "0 15px",
    },
    contentWrapper: {
      marginTop: "60px",
      padding: "0 1rem",
      maxWidth: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      zIndex: 1,
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.8rem",
      fontWeight: "700",
      marginBottom: "1.5rem",
      background: "linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      letterSpacing: "1px",
      textShadow: "0 0 8px rgba(168, 102, 255, 0.6)",
      textAlign: "center",
    },
    contentContainer: {
      marginTop: "20px",
      background: "rgba(0, 4, 20, 0.9)",
      borderRadius: "15px",
      padding: "1.5rem",
      border: "1px solid rgba(173, 102, 255, 0.2)",
    },
    dateText: {
      fontSize: "0.9rem",
      color: "rgba(255, 255, 255, 0.6)",
      marginBottom: "1rem",
      fontStyle: "italic",
      textShadow: "0 0 6px rgba(102, 204, 204, 0.5)",
    },
    subtext: {
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "rgba(255, 255, 255, 0.9)",
      marginBottom: "1.5rem",
      textShadow: "0 0 8px rgba(168, 102, 255, 0.6)",
    },
    pointsContainer: {
      marginTop: "1rem",
    },
    policySection: {
      marginBottom: "1.5rem",
      padding: "1rem",
      background: "rgba(255, 255, 255, 0.05)",
      border: "1px solid rgba(102, 153, 255, 0.3)",
      borderRadius: "8px",
      transition: "all 0.3s ease",
    },
    sectionTitle: {
      fontSize: "1.3rem",
      fontWeight: "600",
      background: "linear-gradient(45deg, #da7eff, #9f10f1, #ff57db, #3f0df7)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      marginBottom: "0.8rem",
      position: "relative",
      paddingLeft: "1.2rem",
      textShadow: "0 0 4px rgba(102, 153, 255, 0.5)",
      "::before": {
        content: "'>>'",
        position: "absolute",
        left: "0",
        color: "#3d33cc",
      },
    },
    pointsList: {
      listStyle: "none",
      paddingLeft: "1rem",
    },
    listItem: {
      marginBottom: "0.8rem",
      position: "relative",
      // paddingLeft: "1.2rem",
      "::before": {
        content: "'•'",
        position: "absolute",
        left: "0",
        color: "#66cccc",
        fontSize: "1rem",
      },
    },
    pointText: {
      fontSize: "0.95rem",
      lineHeight: "1.4",
      color: "rgba(255, 255, 255, 0.8)",
      margin: 0,
    },
  };

  // Tablet styles
  const tabletStyles = {
    ...baseStyles,
    mainContainer: {
      ...baseStyles.mainContainer,
      padding: "0 30px",
    },
    contentWrapper: {
      ...baseStyles.contentWrapper,
      padding: "0 3rem",
    },
    title: {
      ...baseStyles.title,
      fontSize: "2.5rem",
      marginBottom: "2rem",
    },
    contentContainer: {
      ...baseStyles.contentContainer,
      padding: "2rem",
      borderRadius: "20px",
    },
    dateText: {
      ...baseStyles.dateText,
      fontSize: "1rem",
    },
    subtext: {
      ...baseStyles.subtext,
      fontSize: "1.1rem",
    },
    policySection: {
      ...baseStyles.policySection,
      marginBottom: "2rem",
    },
    sectionTitle: {
      ...baseStyles.sectionTitle,
      fontSize: "1.5rem",
    },
    pointText: {
      ...baseStyles.pointText,
      fontSize: "1.1rem",
    },
  };

  // Desktop styles
  const desktopStyles = {
    ...tabletStyles,
    mainContainer: {
      ...tabletStyles.mainContainer,
      padding: "0",
    },
    contentWrapper: {
      ...tabletStyles.contentWrapper,
      marginTop: "100px",
      padding: "0 5rem",
      maxWidth: "1200px",
    },
    title: {
      ...tabletStyles.title,
      fontSize: "3.5rem",
      letterSpacing: "2px",
    },
    contentContainer: {
      ...tabletStyles.contentContainer,
      marginTop: "40px",
      border: "2px solid rgba(173, 102, 255, 0.2)",
    },
    dateText: {
      ...tabletStyles.dateText,
      marginBottom: "1.5rem",
      textShadow: "0 0 8px rgba(102, 204, 204, 0.5)",
    },
    subtext: {
      ...tabletStyles.subtext,
      lineHeight: "1.6",
      marginBottom: "2rem",
      textShadow: "0 0 10px rgba(168, 102, 255, 0.6)",
    },
    pointsContainer: {
      ...tabletStyles.pointsContainer,
      marginTop: "1.5rem",
    },
    policySection: {
      ...tabletStyles.policySection,
      transition: "all 0.3s ease",
      ":hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 0 15px rgba(168, 102, 255, 0.3)",
      },
    },
    sectionTitle: {
      ...tabletStyles.sectionTitle,
      marginBottom: "1rem",
      textShadow: "0 0 5px rgba(102, 153, 255, 0.5)",
    },
    listItem: {
      ...tabletStyles.listItem,
      marginBottom: "1rem",
      "::before": {
        ...tabletStyles.listItem["::before"],
        fontSize: "1.2rem",
      },
    },
  };

  // Select styles based on screen size
  const styles = isMobile
    ? baseStyles
    : isTablet
    ? tabletStyles
    : desktopStyles;

  return (
    <div style={styles.mainContainer}>
      <EducationTopBar
        logo={
          <div
            onClick={() => history.push("/")}
            style={{ display: "flex", alginItems: "center", cursor: "pointer" }}
          >
            <img
              className={isMobile ? "mobile-logo" : "desktop-logo"}
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
              alt=""
            />
          </div>
        }
      />
      <div style={styles.contentWrapper}>
        <h1 style={styles.title}>{policyData.title}</h1>
        <div style={styles.contentContainer}>
          <p style={styles.dateText}>{policyData.date}</p>
          <p style={styles.subtext}>{policyData.subtext}</p>
          {getPoints()}
        </div>
      </div>
      <WorkshopFooterPage />
    </div>
  );
}

export default PrivacyPolicy;
