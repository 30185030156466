import {
  BOOTCAMP_CONFIRM_TIMESLOT_FAILED,
  BOOTCAMP_CONFIRM_TIMESLOT_LOADING,
  BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS,
  BOOTCAMP_GET_MY_PAYMENTS_FAILED,
  BOOTCAMP_GET_MY_PAYMENTS_LOADING,
  BOOTCAMP_GET_MY_PAYMENTS_SUCCESS,
  BOOTCAMP_PAYMENT_FAILED,
  BOOTCAMP_PAYMENT_INITIATION,
  BOOTCAMP_PAYMENT_SUCCESS,
  GET_ALL_BOOTCAMP_FAILURE,
  GET_ALL_BOOTCAMP_REQUEST,
  GET_ALL_BOOTCAMP_SUCCESS,
  GET_BOOTCAMP_BY_ID_FAILURE,
  GET_BOOTCAMP_BY_ID_REQUEST,
  GET_BOOTCAMP_BY_ID_SUCCESS,
  GET_REGISTERED_BOOTCAMPS_FAILURE,
  GET_REGISTERED_BOOTCAMPS_REQUEST,
  GET_REGISTERED_BOOTCAMPS_SUCCESS,
  JOIN_BOOTCAMP_FAILURE,
  JOIN_BOOTCAMP_REQUEST,
  JOIN_BOOTCAMP_SUCCESS,
} from "../../constants/BootCamp/bootCampConstants";

export const bootCampReducer = (state = { bootCamps: [] }, action) => {
  switch (action.type) {
    case GET_ALL_BOOTCAMP_REQUEST:
      return { loading: true, success: false, bootCamps: [] };
    case GET_ALL_BOOTCAMP_SUCCESS:
      return { loading: false, success: true, bootCamps: action.payload };
    case GET_ALL_BOOTCAMP_FAILURE:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const bootCampByIdReducer = (
  state = { bootCampDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_BOOTCAMP_BY_ID_REQUEST:
      return { loading: true, success: false, bootCampDetails: {} };
    case GET_BOOTCAMP_BY_ID_SUCCESS:
      return { loading: false, success: true, bootCampDetails: action.payload };
    case GET_BOOTCAMP_BY_ID_FAILURE:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const joinBootCampReducer = (state = { joinBootCamp: {} }, action) => {
  switch (action.type) {
    case JOIN_BOOTCAMP_REQUEST:
      return { loading: true, success: false, bootCampDetails: {} };
    case JOIN_BOOTCAMP_SUCCESS:
      return { loading: false, success: true, bootCampDetails: action.payload };
    case JOIN_BOOTCAMP_FAILURE:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const getRegisteredBootCampsReducer = (
  state = { registeredBootCamps: {} },
  action
) => {
  switch (action.type) {
    case GET_REGISTERED_BOOTCAMPS_REQUEST:
      return { loading: true, success: false, registeredBootCamps: {} };
    case GET_REGISTERED_BOOTCAMPS_SUCCESS:
      return {
        loading: false,
        success: true,
        registeredBootCamps: action.payload,
      };
    case GET_REGISTERED_BOOTCAMPS_FAILURE:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const bootcampConfirmTimeSlotReducer = (
  state = { confirmTimeSlot: {} },
  action
) => {
  switch (action.type) {
    case BOOTCAMP_CONFIRM_TIMESLOT_LOADING:
      return { loading: true, success: false, confirmTimeSlot: {} };
    case BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS:
      return {
        loading: false,
        success: true,
        confirmTimeSlot: action.payload,
      };
    case BOOTCAMP_CONFIRM_TIMESLOT_FAILED:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const bootcampPaymentReducer = (
  state = { bootcampPaymentInfo: {} },
  action
) => {
  switch (action.type) {
    case BOOTCAMP_PAYMENT_INITIATION:
      return { loading: true, success: false, bootcampPaymentInfo: {} };
    case BOOTCAMP_PAYMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        bootcampPaymentInfo: action.payload,
      };
    case BOOTCAMP_PAYMENT_FAILED:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const bootcampGetMyPaymentsReducer = (
  state = { myPaymentsData: {} },
  action
) => {
  switch (action.type) {
    case BOOTCAMP_GET_MY_PAYMENTS_LOADING:
      return { loading: true, success: false, myPaymentsData: {} };
    case BOOTCAMP_GET_MY_PAYMENTS_SUCCESS:
      return {
        loading: false,
        success: true,
        myPaymentsData: action.payload,
      };
    case BOOTCAMP_GET_MY_PAYMENTS_FAILED:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
