import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import EventIcon from "@mui/icons-material/Event";
import SchoolIcon from "@mui/icons-material/School";
import VerifiedIcon from "@mui/icons-material/Verified";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import { userLogoutAction } from "../../../actions/userActions";

export default function AIDrivenDrawer({ isOpen, toggleDrawer }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleListItemClicked = (index) => {
    if (index === 0) history.push("/");
    else if (index === 1) history.push("/events");
    else if (index === 2) history.push("/courses");
    else if (index === 3) history.push("/certificates");
    else if (index === 4) history.push("/about");
    toggleDrawer();
  };

  const handleSignOut = () => {
    dispatch(userLogoutAction());
    toggleDrawer();
  };

  const list = (anchor) => (
    <Box
      sx={{
        color: "#fff", // White text
        backgroundColor: "#000", // Black background
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        padding: "10px",
      }}
      role="presentation"
    >
      {/* Close Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          onClick={toggleDrawer}
          sx={{
            color: "#fff",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <List>
        {["Home Base", "Events", "AI Courses", "Certificates", "About AI"].map(
          (text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => handleListItemClicked(index)}
                sx={{
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                <ListItemIcon sx={{ color: "#fff" }}>
                  {index === 0 ? (
                    <SmartToyIcon />
                  ) : index === 1 ? (
                    <EventIcon />
                  ) : index === 2 ? (
                    <SchoolIcon />
                  ) : index === 3 ? (
                    <VerifiedIcon />
                  ) : (
                    <InfoIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      {text}
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>

      <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }} />

      {userInfo ? (
        <Box sx={{ padding: "10px" }}>
          <Button
            onClick={handleSignOut}
            sx={{
              width: "100%",
              backgroundColor: "#9f10f1", // Solid purple from gradient
              color: "#fff",
              padding: "10px",
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#da7eff", // Lighter purple on hover
              },
            }}
          >
            Sign Out
          </Button>
        </Box>
      ) : (
        <List>
          {["Sign In", "Sign Up"].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() =>
                  index === 0 ? history.push("/login") : history.push("/signup")
                }
                sx={{
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "5px 10px",
                  borderRadius: "8px",
                  backgroundColor: index === 0 ? "transparent" : "#9f10f1", // Purple for Sign Up
                  border: index === 0 ? "1px solid #da7eff" : "none", // Light purple border for Sign In
                  color: "#fff",
                  "&:hover": {
                    backgroundColor:
                      index === 0 ? "rgba(218, 126, 255, 0.1)" : "#da7eff", // Purple hover effects
                  },
                }}
              >
                <ListItemText
                  primary={
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      {text}
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={isOpen}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              backgroundColor: "#000", // Black background for drawer
            },
          }}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
