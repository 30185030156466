import { Avatar, Button, Popover } from "@mui/material";
import React, { useState } from "react";
import "./EducationTopBar.css";
import { ImageConstants } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAction } from "../../../actions/userActions";

function EducationTopBar({ logo }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(userLogoutAction());

    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  return (
    <div className="educationTopbarContainer">
      {/* Logo */}
      {logo ? (
        logo
      ) : (
        <div onClick={() => history.push("/")} className="logo">
          <img src={ImageConstants.main_logo} alt="Logo" />
        </div>
      )}

      {/* Right Section */}
      <div className="right-section">
        {!userInfo ? (
          <div className="auth-buttons">
            <Button
              onClick={() => history.push("/login")}
              className="sign-in-btn"
            >
              Sign In
            </Button>
            <Button
              onClick={() => history.push("/signup")}
              className="sign-up-btn"
            >
              Sign Up
            </Button>
          </div>
        ) : (
          <div className="user-profile" onClick={handleClick}>
            <Avatar
              sx={{ width: 36, height: 36, bgcolor: "rgb(96, 64, 238)" }}
              className="user-avatar"
            >
              {userInfo?.firstName[0]?.toUpperCase()}
            </Avatar>
            <span className="user-name">{userInfo?.firstName}</span>
          </div>
        )}
      </div>

      {/* Profile Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
            color: "#fff",
          },
        }}
      >
        <div className="popover-content">
          <button
            className="popover-item"
            onClick={() => {
              history.push("/dashboard");
              handleClose();
            }}
          >
            Dashboard
          </button>
          <button
            className="popover-item"
            onClick={() => {
              history.push("/");
              handleClose();
            }}
          >
            Go to home
          </button>
          <button
            className="popover-item"
            onClick={() => {
              history.push("/about");
              handleClose();
            }}
          >
            About Us
          </button>
          <button className="popover-item logout-btn" onClick={handleSignOut}>
            Logout
          </button>
        </div>
      </Popover>
    </div>
  );
}

export default EducationTopBar;
