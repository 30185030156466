import React, { useState } from "react";
import "./CertificatesScreen.css";
import { Helmet } from "react-helmet";
import EducationTopBar from "./topbar/EducationTopBar";
import { Avatar, Button } from "@mui/material";
import Typewriter from "typewriter-effect";
import CertificateMotionSection from "./reusables/CertificateMotionSection";
import ContinuousCarousel from "./reusables/ContinuousCarousel";
import EducationFooter from "./footer/education_footer";
import Carousel from "react-material-ui-carousel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MarqueeScroll from "./reusables/MarqueeScroll";
import RunningCounter from "./reusables/RunningAnimation";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ImageConstants } from "../../utils/constants";

const tempCertificateData = [
  {
    image:
      "https://images.unsplash.com/photo-1731978009363-21fa723e2cbe?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "FrontEnd Certificate",
    subtitle:
      "One of the best in the market. More than 75% chances of grabbing the job - Priya",
  },
  {
    image:
      "https://images.unsplash.com/photo-1731978009363-21fa723e2cbe?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Backend Certificate",
    subtitle:
      "One of the best in the market. More than 75% chances of grabbing the job - Priya",
  },
  {
    image:
      "https://images.unsplash.com/photo-1731978009363-21fa723e2cbe?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "FullStack Certificate",
    subtitle:
      "One of the best in the market. More than 75% chances of grabbing the job - Priya",
  },
  {
    image:
      "https://images.unsplash.com/photo-1731978009363-21fa723e2cbe?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "React.js Programme Certificate",
    subtitle:
      "One of the best in the market. More than 75% chances of grabbing the job - Priya",
  },
];

const collegeData = [
  {
    id: 1,
    src: "https://medha.org.in/user-content/media-versions/1400w_x2400h__q_90_c_Navgurukul.jpg",
    name: "Navgurukul",
  },
  {
    id: 2,
    src: "https://d2lk14jtvqry1q.cloudfront.net/media/large_177_35e5fdfc68_7f73ce2c67.png",
    name: "Indira Gandhi Technical University for Women",
  },
  {
    id: 3,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzPKlZeJZBWaQFzyzbQxPxzE_5WqVx5jZJ6Q&s",
    name: "Nutan College of Engineering and Research ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
];

const reviews = [
  {
    id: 1,
    profileImage:
      "https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    fullName: "Shreyash Katare",
    review:
      "It was a great experience with the Data science essentials and full stack data science program. The mentor was amazing and all topics were discussed from the basic level which made it easier to understand for a beginner and non programmer like me. Project was really helpful in understanding the data science process and how to handle or start the project. recommend this course for beginners and non programming peoples.",
  },
  {
    id: 1,
    profileImage:
      "https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    fullName: "Shreyash Katare",
    review:
      "It was a great experience with the Data science essentials and full stack data science program. The mentor was amazing and all topics were discussed from the basic level which made it easier to understand for a beginner and non programmer like me. Project was really helpful in understanding the data science process and how to handle or start the project. recommend this course for beginners and non programming peoples.",
  },
  {
    id: 1,
    profileImage:
      "https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    fullName: "Shreyash Katare",
    review:
      "It was a great experience with the Data science essentials and full stack data science program. The mentor was amazing and all topics were discussed from the basic level which made it easier to understand for a beginner and non programmer like me. Project was really helpful in understanding the data science process and how to handle or start the project. recommend this course for beginners and non programming peoples.",
  },
  {
    id: 1,
    profileImage:
      "https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    fullName: "Shreyash Katare",
    review:
      "It was a great experience with the Data science essentials and full stack data science program. The mentor was amazing and all topics were discussed from the basic level which made it easier to understand for a beginner and non programmer like me. Project was really helpful in understanding the data science process and how to handle or start the project. recommend this course for beginners and non programming peoples.",
  },
  {
    id: 1,
    profileImage:
      "https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    fullName: "Shreyash Katare",
    review:
      "It was a great experience with the Data science essentials and full stack data science program. The mentor was amazing and all topics were discussed from the basic level which made it easier to understand for a beginner and non programmer like me. Project was really helpful in understanding the data science process and how to handle or start the project. recommend this course for beginners and non programming peoples.",
  },
];
function CertificatesScreen() {
  const history = useHistory();
  const [selectedLevel, setSelectedLevel] = useState("frontendbasic");
  const [selectedBackEndLevel, setSelectedBackEndLevel] =
    useState("backendbasic");
  const [selectedFullStackLevel, setSelectedFullStackLevel] =
    useState("fullstackbasic");

  const getHeadlineImage = () => {
    return (
      <Carousel
        autoPlay={true}
        indicators={true}
        navButtonsAlwaysInvisible={true}
      >
        {tempCertificateData?.map((item, index) => (
          <div key={index} className="certificate_page1_right">
            <div className="cert_page1_image_container">
              <img src={item?.image} alt="" />
            </div>
            <div className="cert_page1_image_details">
              <div>
                <h1>"{item.title}"</h1>
              </div>
              <div>
                <p>{item.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    );
  };
  const getHeadline = () => {
    return (
      <div className="certificates_headline_container">
        <div className="certificates_headline">
          <div>
            <h1
              style={{
                fontSize: "3.2rem",
                margin: "10px 0px",
                fontFamily: "Questrial",
                fontWeight: "400",
              }}
            >
              {" "}
              Grab Your Certificates for{" "}
            </h1>
            <Typewriter
              options={{
                strings: ["FrontEnd", "BackEnd", "FullStack"],
                autoStart: true,
                loop: true,
                wrapperClassName: "certificate_typewriter",
                cursorClassName: "certificate_cursor",
              }}
            />{" "}
            <h1
              style={{
                fontSize: "3.2rem",
                margin: "10px 0px",
                fontFamily: "Questrial",
                fontWeight: "400",
              }}
            >
              {" "}
              Development
            </h1>
          </div>
          <p>
            "Empowering students with comprehensive certification programs in
            frontend, backend, and full-stack development. Our curriculum spans
            basic, intermediate, and advanced levels, ensuring holistic skill
            development. Join us to embark on a journey of mastery and unlock
            limitless opportunities in the dynamic field of web development."
          </p>
        </div>
        <div className="certificate_headline_buttonContainer">
          <div className="certificate_headline_button">
            <Button>Get Certified Now</Button>
          </div>
        </div>
      </div>
    );
  };

  const certificationPage2 = () => {
    return (
      <div
        style={{
          backgroundImage: "url(/images/page2.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "50px",
          color: "white",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            padding: "20px",
            borderRadius: "22px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          {/* certfication title section */}
          <section style={{ fontFamily: "Questrial" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
              <h1 style={{ whiteSpace: "nowrap", margin: "0px 10px" }}>
                Certification Programme
              </h1>
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "38px",
                  fontStyle: "italic",
                  fontWeight: "800",
                  margin: "8px",
                }}
              >
                Everything From Basic to Advanced
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>
                Earn Basic to Advanced Certifications and Be a part of
                technology Workshops and Internships.
              </p>
            </div>
          </section>
          <section>
            <div
              className="certification_page2_card"
              style={{
                display: "flex",
                alignItems: "center",
                overflowX: "auto",
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => certificateCard())}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4 className="view_all_certification">
                  View All Certificate Programs
                </h4>
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };

  const certificateCard = () => {
    return (
      <div className="certificate_card">
        <div>
          <img
            width={300}
            src="https://plus.unsplash.com/premium_photo-1677094310919-d0361465d3be?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            style={{
              borderTopLeftRadius: "22px",
              borderTopRightRadius: "22px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CalendarMonthIcon
              style={{
                fontSize: "16px",
                marginRight: "5px",
                marginBottom: "2px",
              }}
            />
            <h4 style={{ fontSize: "14px", fontWeight: "400", margin: "0px" }}>
              21st December
            </h4>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GroupsIcon
              style={{
                fontSize: "16px",
                marginRight: "5px",
                marginBottom: "2px",
              }}
            />
            <h4 style={{ fontSize: "14px", fontWeight: "400" }}>
              30 Spots Left
            </h4>
          </div>
        </div>
        <div style={{ margin: "0px 10px" }}>
          <h3 style={{ margin: "0px" }}>ChatGPT 101 Learn</h3>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 10px",
            }}
          >
            {[1, 2, 3, 4].map((item) => (
              <div>
                <Avatar
                  sx={{ width: "30px", height: "30px" }}
                  src="https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  style={{ marginRight: "-15px" }}
                />
              </div>
            ))}
            <h4 style={{ marginLeft: "20px", fontFamily: "Questrial" }}>
              +3 Registered
            </h4>
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <Button className="enroll_button">Enroll Now</Button>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>
              <span style={{ textDecoration: "line-through" }}>₹4999</span>{" "}
              <span style={{ margin: "0px 5px" }}>•</span> ₹0
            </p>
          </div>
        </div>
      </div>
    );
  };

  const certificationPage3 = () => {
    return (
      <div
        style={{
          backgroundImage: "url(/images/page3.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            color: "#fff",
            paddingBottom: "10px",
            borderRadius: "22px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <section style={{ fontFamily: "Questrial" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
              <h1 style={{ whiteSpace: "nowrap", margin: "0px 10px" }}>
                Our Presence Spans
              </h1>
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "38px",
                  fontStyle: "italic",
                  fontWeight: "800",
                  margin: "8px",
                }}
              >
                Students from top colleges upskill with AceTechies
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>
                Students enroll in universities for degrees, but they turn to
                LetsUpgrade for skills
              </p>
            </div>
          </section>
          <section>
            <MarqueeScroll content={collegeData} direction="left" speed={30} />
          </section>
          <section className="college_certification_button">
            <div>
              <h1 style={{ fontFamily: "Questrial", fontSize: "26px" }}>
                Join a community of students dedicated to upskilling
              </h1>
            </div>
            <div>
              <RunningCounter target={2000} />
            </div>
            <Button>Explore Program Now</Button>
            <div>
              <h4 style={{ fontFamily: "Questrial", fontSize: "24px" }}>
                Approximately 100 students enroll in LetsUpgrade programs every
                day
              </h4>
            </div>
          </section>
        </div>
      </div>
    );
  };

  const certificationPage4 = () => {
    return (
      <div
        style={{
          backgroundImage: "url(/images/page4.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            color: "#fff",
            paddingBottom: "10px",
            borderRadius: "22px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <section style={{ fontFamily: "Questrial" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
              <h1 style={{ whiteSpace: "nowrap", margin: "0px 10px" }}>
                Student Ambassador
              </h1>
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "38px",
                  fontStyle: "italic",
                  fontWeight: "800",
                  margin: "8px",
                }}
              >
                Become our Brand Ambassadors
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>
                A brand building program that nurtures your inherent leadership
                and representational skills
              </p>
            </div>
          </section>
          <section>
            <div
              style={{
                display: "flex",
                padding: "0px 50px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div>
                  <h1
                    style={{
                      fontFamily: "Questrial",
                      fontSize: "34px",
                      fontStyle: "italic",
                      lineHeight: "40px",
                    }}
                  >
                    Apply to Become An Ambassador
                  </h1>
                  <p
                    style={{
                      fontFamily: "Questrial",
                      lineHeight: "40px",
                      width: "50vw",
                      fontSize: "22px",
                      fontWeight: "300",
                    }}
                  >
                    It's super easy to become a student ambassador for
                    LetsUpgrade! Earn premium swag by representing LetsUpgrade
                    on your college or university campus and encouraging fellow
                    students to join various programs.
                  </p>
                  <div className="ambassador_join_button">
                    <Button>Join Now</Button>
                  </div>
                </div>
              </div>
              <div className="certification_ambassador_images">
                <img src="/images/ambassador_certificate1.png" alt="" />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    style={{ marginRight: "80px" }}
                    src="/images/ambassador_certificate2.png"
                    alt=""
                  />
                  <img src="/images/ambassador_certificate3.png" alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };

  const certificationPage5 = () => {
    return (
      <div
        style={{
          backgroundImage: "url(/images/page4.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            color: "#fff",
            paddingBottom: "10px",
            borderRadius: "22px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <section style={{ fontFamily: "Questrial" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
              <h1 style={{ whiteSpace: "nowrap", margin: "0px 10px" }}>
                Reviews
              </h1>
              <img
                className="certification_line"
                src="/images/line.png"
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "38px",
                  fontStyle: "italic",
                  fontWeight: "800",
                  margin: "8px",
                }}
              >
                What our students have to say
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>Our commitment to transform education speaks itself below</p>
            </div>
          </section>
          <section>
            {[1, 2, 3, 4].map((item) => {
              return (
                <MarqueeScroll
                  direction={item % 2 === 0 ? "right" : "left"}
                  content={reviews}
                  isReview={true}
                  speed={60}
                />
              );
            })}
          </section>
        </div>
      </div>
    );
  };

  const footerPage = () => {
    const currentYear = new Date().getFullYear();
    return (
      <div
        style={{
          backgroundColor: "black",
          padding: "40px 80px",
          color: "#fff",
          fontFamily: "Questrial",
        }}
      >
        <div>
          <h1>AceTechies Academy</h1>
          <p style={{ margin: "0px 0px 20px 0px" }}>
            Feel free to send us your questions
          </p>
          <div style={{ margin: "60px 0px 30px 0px" }}>
            <a
              href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=acetechiesacademy@gmail.com`}
              target="_blank"
              rel="noreferrer"
              className="footer_send_button"
            >
              Send A Message
            </a>
          </div>
        </div>
        <hr color="#222" style={{ margin: "40px 0px" }} />
        <div className="footer_content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Home</p>
              <p>Events</p>
              <p>Certifications</p>
            </div>
            <div>
              <p onClick={() => history.push("terms")}>Terms Of Use</p>
              <p onClick={() => history.push("privacy")}>Privacy Policy</p>
              <p onClick={() => history.push("/cancellation-refund")}>
                Refund & Cancellation
              </p>
              <p onClick={() => history.push("contact")}>Contact Us</p>
            </div>
            <div>
              <h2>Contact</h2>
              <a
                href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=acetechiesacademy@gmail.com`}
                target="_blank"
                rel="noreferrer"
              >
                acetechiesacademy@gmail.com
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <InstagramIcon
                style={{
                  color: "#fff",
                  marginRight: "30px",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              />
              <FacebookIcon
                style={{
                  color: "#fff",
                  marginRight: "30px",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              />
              <LinkedInIcon
                style={{ color: "#fff", cursor: "pointer", fontSize: "30px" }}
              />
            </div>
            <div>
              <p
                style={{
                  color: "#fff",
                }}
              >
                Copyright © {currentYear} AceTech. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const educationTopBar = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "black",
          padding: "20px 0px",
          color: "white",
          position: "sticky",
          top: "0px",
          width: "100%",
          zIndex: "100",
          borderBottom: "1px solid #f0e9e9a3",
        }}
      >
        <div onClick={() => history.push("/")}>
          <img
            style={{ width: "150px" }}
            src={ImageConstants.main_logo}
            alt=""
          />
        </div>
        <div className="top_options_container">
          <h2 onClick={() => history.push("/")}>Home</h2>
          <h2>Events</h2>
          <h2>Courses</h2>
          <h2 onClick={() => history.push("/certificates")}>Certificates</h2>
          <h2>About Us</h2>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <Button
              style={{
                color: "#fff",
                border: "1px solid white",
                padding: "5px 30px",
                textTransform: "inherit",
              }}
              onClick={() => history.push("/login")}
            >
              Sign In
            </Button>
          </div>
          <div>
            <Button
              onClick={() => history.push("/signup")}
              style={{
                backgroundColor: "#fff",
                color: "black",
                padding: "5px 30px",
                textTransform: "inherit",
                margin: "0px 20px",
              }}
            >
              Sign UP
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Certificates</title>
        <link
          rel="canonical"
          href={
            "https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
          }
        />
      </Helmet>
      {/* <EducationTopBar /> */}
      {educationTopBar()}
      <div
        style={{
          backgroundImage: "url(/images/certification_page_background1.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            display: "flex",
            color: "#fff",
          }}
        >
          <div className="certificate_top_container">
            <div>{getHeadline()}</div>
            {<div style={{ width: "300px" }}>{getHeadlineImage()}</div>}
          </div>
        </div>
      </div>

      {certificationPage2()}
      {certificationPage3()}
      {certificationPage4()}
      {certificationPage5()}
      {footerPage()}

      {/* <CertificateMotionSection
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
        currentCourseLevel={"frontend"}
      />
      <CertificateMotionSection
        selectedLevel={selectedBackEndLevel}
        setSelectedLevel={setSelectedBackEndLevel}
        currentCourseLevel={"backend"}
      />
      <CertificateMotionSection
        selectedLevel={selectedFullStackLevel}
        setSelectedLevel={setSelectedFullStackLevel}
        currentCourseLevel={"fullstack"}
      />
      <div className="certificates_acknowledgements_container">
        <h1>Acknowledgement</h1>
        <ContinuousCarousel />
      </div>
      <div>
        <EducationFooter margin={"80px"} />
      </div> */}
    </div>
  );
}

export default CertificatesScreen;
