export const GET_ALL_BOOTCAMP_REQUEST = "GET_ALL_BOOTCAMP_REQUEST";
export const GET_ALL_BOOTCAMP_SUCCESS = "GET_ALL_BOOTCAMP_SUCCESS";
export const GET_ALL_BOOTCAMP_FAILURE = "GET_ALL_BOOTCAMP_FAILURE";

export const GET_BOOTCAMP_BY_ID_REQUEST = "GET_BOOTCAMP_BY_ID_REQUEST";
export const GET_BOOTCAMP_BY_ID_SUCCESS = "GET_BOOTCAMP_BY_ID_SUCCESS";
export const GET_BOOTCAMP_BY_ID_FAILURE = "GET_BOOTCAMP_BY_ID_FAILURE";

export const JOIN_BOOTCAMP_REQUEST = "JOIN_BOOTCAMP_REQUEST";
export const JOIN_BOOTCAMP_SUCCESS = "JOIN_BOOTCAMP_SUCCESS";
export const JOIN_BOOTCAMP_FAILURE = "JOIN_BOOTCAMP_FAILURE";

export const GET_REGISTERED_BOOTCAMPS_REQUEST =
  "GET_REGISTERED_BOOTCAMPS_REQUEST";
export const GET_REGISTERED_BOOTCAMPS_SUCCESS =
  "GET_REGISTERED_BOOTCAMPS_SUCCESS";
export const GET_REGISTERED_BOOTCAMPS_FAILURE =
  "GET_REGISTERED_BOOTCAMPS_FAILURE";

export const BOOTCAMP_CONFIRM_TIMESLOT_LOADING =
  "BOOTCAMP_CONFIRM_TIMESLOT_LOADING";
export const BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS =
  "BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS";
export const BOOTCAMP_CONFIRM_TIMESLOT_FAILED =
  "BOOTCAMP_CONFIRM_TIMESLOT_FAILED";

export const BOOTCAMP_PAYMENT_INITIATION = "BOOTCAMP_PAYMENT_INITIATION";
export const BOOTCAMP_PAYMENT_SUCCESS = "BOOTCAMP_PAYMENT_SUCCESS";
export const BOOTCAMP_PAYMENT_FAILED = "BOOTCAMP_PAYMENT_FAILED";

export const BOOTCAMP_GET_MY_PAYMENTS_LOADING =
  "BOOTCAMP_GET_MY_PAYMENTS_LOADING";
export const BOOTCAMP_GET_MY_PAYMENTS_SUCCESS =
  "BOOTCAMP_GET_MY_PAYMENTS_SUCCESS";
export const BOOTCAMP_GET_MY_PAYMENTS_FAILED =
  "BOOTCAMP_GET_MY_PAYMENTS_FAILED";

export const BOOTCAMP_MARK_PAYMENTS_VIEWED = "BOOTCAMP_MARK_PAYMENTS_VIEWED";
