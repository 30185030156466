import React, { useEffect, useState } from "react";
import "./WorkshopDetails.css";
import EducationTopBar from "../topbar/EducationTopBar";
import YouTube from "react-youtube";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SchoolIcon from "@mui/icons-material/School";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarqueeScroll from "../reusables/MarqueeScroll";
import { collegeData, faqs } from "../../../utils/constants";
import WorkshopFooterPage from "./WorkshopFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  getBootCampByIdAction,
  joinBootCampAction,
} from "../../../actions/BootCamp/bootcampActions";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "../../../utils/change_time_format";
import MySkeletonLoader from "../reusables/MySkeletonLoader";
import { redirectToPageAction } from "../../../actions/userActions";
import {
  CLEAR_REDIRECT_DATA,
  REDIRECT_BOOTCAMP_BYID_PAGE,
} from "../../../constants/WebinarEvents/webinarEventConstants";
import { JoinWorkshopModal } from "./JoinWorkshopModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Helmet } from "react-helmet";

function WorkshopDetails() {
  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { bootCampDetails, loading } = useSelector(
    (state) => state.bootCampByIdStore
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const { redirectUrl } = useSelector((state) => state.redirectUrlStore);

  useEffect(() => {
    dispatch(getBootCampByIdAction(id));
  }, [id]);
  useEffect(() => {
    if (redirectUrl?.pendingStatus && userInfo) {
      setIsClicked(true);
      dispatch(joinBootCampAction(id));

      dispatch({ type: CLEAR_REDIRECT_DATA });
    }
  }, [redirectUrl]);
  const [isClicked, setIsClicked] = useState(false);

  const handleJoinNowClick = () => {
    if (userInfo) {
      setIsClicked(true);
      dispatch(joinBootCampAction(id));
    } else {
      history.push("/login");
      dispatch(
        redirectToPageAction(REDIRECT_BOOTCAMP_BYID_PAGE, {
          url: "/workshop/details/" + id,
          pendingStatus: true,
        })
      );
    }
  };

  const handleClose = () => {
    setIsClicked(false);
  };

  const showMoneyDetails = (bootCampDetails) => {
    return (
      <div className="stat_item">
        <h3>
          Free
          <span className="info-icon">
            <InfoOutlinedIcon style={{ fontSize: "14px" }} />
            <div className="tooltip">
              Day 1 is free! Upcoming days are paid. More payment info will be
              updated in your dashboard post login.
            </div>
          </span>
        </h3>
        <p>
          <s>₹{bootCampDetails?.price?.originalPrice}</s>
        </p>
      </div>
    );
  };
  const DesktopLayout = () => {
    const date = formatDate(
      bootCampDetails?.dates?.startDate ?? "2025-03-02T00:00:00.000Z"
    );
    const media =
      bootCampDetails?.media?.length > 0 ? bootCampDetails?.media : null;
    return (
      <div className="workshopdetails_desktop">
        <div className="desktop_left_column">
          <div className="workshopDetails_part1_imageContainer">
            {loading ? (
              <MySkeletonLoader width={500} height={400} />
            ) : media?.length > 0 && media[0]?.type === "video" ? (
              <div className="desktop-workshop-youtube-container">
                <YouTube
                  videoId={media[0]?.url}
                  opts={{
                    playerVars: {
                      autoplay: 0,
                    },
                  }}
                />
              </div>
            ) : (
              <img
                src={
                  (bootCampDetails?.media?.length > 0 &&
                    bootCampDetails?.media[0]?.url) ||
                  "https://letsupgrade.in/programs/_next/image?url=https%3A%2F%2Flucdn.letsupgrade.net%2Fassets%2Fchatgpt_66b0b9782ebb0_db11c02d95.webp&w=1080&q=75"
                }
                alt=""
              />
            )}
          </div>
          <div className="desktop_course_info">
            <h2>{bootCampDetails?.title}</h2>
            <p className="course_description">{bootCampDetails?.description}</p>
            <div className="desktop_stats_grid">
              {loading ? (
                <MySkeletonLoader width={140} height={90} />
              ) : (
                <div className="stat_item">
                  <h3>
                    {date?.day} {date?.month}, {date?.year}
                  </h3>
                  <p>Upcoming Batch</p>
                </div>
              )}
              {loading ? (
                <MySkeletonLoader width={140} height={90} />
              ) : (
                showMoneyDetails(bootCampDetails)
              )}
              {loading ? (
                <MySkeletonLoader width={140} height={90} />
              ) : (
                <div className="stat_item">
                  <h3>
                    {bootCampDetails?.registeredUsers?.length >= 20
                      ? bootCampDetails?.registeredUsers?.length
                      : 20}
                    +
                  </h3>
                  <p>Learners Enrolled</p>
                </div>
              )}
            </div>
            <div className="desktop_features_grid">
              <div className="feature_item">
                <CastForEducationIcon
                  style={{ color: "red", fontSize: "35px" }}
                />
                <h4>100% Live Classes</h4>
              </div>
              <div className="feature_item">
                <img
                  style={{ width: "35px" }}
                  src="/images/certificate_icon.png"
                  alt=""
                />
                <h4>Certification</h4>
              </div>
              <div className="feature_item">
                <TimelapseIcon style={{ color: "#fff", fontSize: "35px" }} />
                <h4>3 Days Coding</h4>
              </div>
              <div className="feature_item">
                <SchoolIcon style={{ color: "green", fontSize: "35px" }} />
                <h4>Hands-on Project</h4>
              </div>
            </div>
          </div>
          {loading ? (
            <MySkeletonLoader width={440} height={400} />
          ) : (
            <div className="desktop_learning_outcomes">
              <h3>What You'll Learn</h3>
              <ul className="learning_list">
                {bootCampDetails?.whatYouWillLearn?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              {bootCampDetails?.registeredUsers?.includes(userInfo?._id) ? (
                <button
                  onClick={() => history.push("/dashboard")}
                  className="dashboard-btn"
                >
                  Go to Dashboard
                  <span className="arrow-icon">→</span>
                </button>
              ) : (
                <button
                  onClick={() => handleJoinNowClick()}
                  className="shimmer-btn desktop_enroll_btn"
                >
                  Enroll Now
                </button>
              )}
            </div>
          )}
        </div>

        <div className="desktop_right_column">
          {loading ? (
            <div
              style={{
                padding: "0px 20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {[1, 2, 3].map((_, index) => (
                <MySkeletonLoader key={index} width={400} height={100} />
              ))}
            </div>
          ) : (
            <div className="desktop_accordion_section">{showAccordians()}</div>
          )}
          <div className="desktop_certification_section">
            <h4>Industry Certification</h4>
            <h2>Get Your Hardwork Certified</h2>
            <p>
              Earn certificates for each workshop and showcase your skills to
              recruiters.
            </p>
            <img
              src="https://res.cloudinary.com/cqn/image/upload/v1742050003/new_certificate_kqhz2h.png"
              alt=""
            />
          </div>
        </div>

        <div className="desktop_full_width_section">
          <div className="college_presence">
            <h4>Our Presence Spans</h4>
            <h2>Students From Top Colleges Upskill with Acetechies Academy</h2>
            <p>
              Students Learn from us what is actually relevant in the industry.
            </p>
            <MarqueeScroll content={collegeData} />
          </div>

          <div className="faqs_section">
            <h4>FAQs</h4>
            <h2>Frequently Asked Questions</h2>
            <p>Have any doubts? Let's clear them below</p>
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                className="faq_accordion"
                style={{
                  marginBottom: "20px",
                  backgroundColor: "rgb(63 40 112)",
                  padding: "0px",
                  borderRadius: "8px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                >
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails>
                  <h4>{faq.answer}</h4>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const part1 = () => {
    const date = formatDate(
      bootCampDetails?.dates?.startDate ?? "2025-03-02T00:00:00.000Z"
    );
    const media =
      bootCampDetails?.media?.length > 0 ? bootCampDetails?.media : null;

    return (
      <div>
        <div className="workshopDetails_part1_imageContainer">
          {loading ? (
            <MySkeletonLoader width={300} height={300} />
          ) : media?.length > 0 && media[0]?.type === "video" ? (
            <div className="desktop-workshop-youtube-container">
              <YouTube
                videoId={media[0]?.url}
                opts={{
                  playerVars: {
                    autoplay: 0,
                  },
                }}
              />
            </div>
          ) : (
            <img
              src={
                (bootCampDetails?.media?.length > 0 &&
                  bootCampDetails?.media[0]?.url) ||
                "https://letsupgrade.in/programs/_next/image?url=https%3A%2F%2Flucdn.letsupgrade.net%2Fassets%2Fchatgpt_66b0b9782ebb0_db11c02d95.webp&w=1080&q=75"
              }
              alt=""
            />
          )}
        </div>
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontFamily: "Questrial",
            margin: "20px 10px",
          }}
        >
          {loading ? (
            <MySkeletonLoader width={120} height={80} />
          ) : (
            <div className="workshopDetails_part1_text">
              <h3>
                {date?.day} {date?.month}, {date?.year}
              </h3>
              <p>Upcoming Batch</p>
            </div>
          )}
          {loading ? (
            <MySkeletonLoader width={80} height={80} />
          ) : (
            showMoneyDetails(bootCampDetails)
          )}
          {loading ? (
            <MySkeletonLoader width={120} height={80} />
          ) : (
            <div className="workshopDetails_part1_text">
              <h3>
                {bootCampDetails?.registeredUsers?.length >= 20
                  ? bootCampDetails?.registeredUsers?.length
                  : 20}
                +
              </h3>
              <p>Learners Enrolled</p>
            </div>
          )}
        </div>
        <div style={{ margin: "20px 20px" }}>
          <h2 style={{ margin: "5px 0px", lineHeight: "1.4" }}>
            {bootCampDetails?.title}
          </h2>
          <p
            style={{
              margin: "10px 0px",
              fontSize: "14px",
              color: "rgb(189 189 189)",
              lineHeight: "1.5",
            }}
          >
            {bootCampDetails?.description}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div className="workshopDetails_part1_icon_details">
              <CastForEducationIcon
                style={{ color: "red", fontSize: "35px" }}
              />
              <h4>100% Live Classes</h4>
            </div>

            <div className="workshopDetails_part1_icon_details">
              <img
                style={{ width: "35px" }}
                src="/images/certificate_icon.png"
                alt=""
              />
              <h4>Certification</h4>
            </div>
            <div className="workshopDetails_part1_icon_details">
              <TimelapseIcon style={{ color: "#fff", fontSize: "35px" }} />
              <h4>3 Days Coding</h4>
            </div>
            <div className="workshopDetails_part1_icon_details">
              <SchoolIcon style={{ color: "green", fontSize: "35px" }} />
              <h4>Hands-on Project</h4>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              padding: "0px 20px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MySkeletonLoader width={300} height={100} />
            <MySkeletonLoader width={300} height={100} />
            <MySkeletonLoader width={300} height={100} />
          </div>
        ) : (
          <div style={{ padding: "0px 20px" }}>{showAccordians()}</div>
        )}
      </div>
    );
  };

  const showAccordians = () => {
    return bootCampDetails?.daywiseDetails?.map((item, index) => {
      return (
        <Accordion
          key={index}
          style={{
            marginBottom: "20px",
            backgroundColor: "#e2e2e2",
            padding: "0px",
            borderRadius: "8px",
          }}
          // onChange={() => handleChange(index)}
          // expanded={openedAccordian.includes(index)}
          defaultExpanded={index === 0 ? true : false}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "#222", fontSize: "30px" }} />
            }
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontSize: "18px",
              color: "black",
              fontWeight: "800",
              fontFamily: "Questrial",
            }}
          >
            Day {index + 1}
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <h3 style={{ fontStyle: "italic", margin: "0px 0px 20px 0px" }}>
                {item?.title}
              </h3>
              <ul className="learning_list">
                {bootCampDetails?.daywiseDetails[index]?.description?.map(
                  (value, index) => (
                    <li style={{ color: "#222" }} key={index}>
                      {value}
                    </li>
                  )
                )}
              </ul>
            </div>
            {bootCampDetails?.registeredUsers?.includes(userInfo?._id) ? (
              <button
                onClick={() => history.push("/dashboard")}
                className="dashboard-btn"
              >
                Go to Dashboard
                <span className="arrow-icon">→</span>
              </button>
            ) : (
              <button
                onClick={() => handleJoinNowClick()}
                className="shimmer-btn"
              >
                Join Now
              </button>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const part2 = () => {
    return (
      <div style={{ padding: "0px 20px" }}>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "#fff",
          }}
        >
          <h4 style={{ margin: "5px 0px" }}>Industry Certification</h4>
          <h2 style={{ margin: "10px 0px" }}>Get Your Hardwork Certified</h2>
          <h3
            style={{
              color: "#bbb6b6",
              margin: "10px 20px",
              fontSize: "18px",
              lineHeight: "30px",
              textAlign: "center",
            }}
          >
            Earn certificates for each workshop and showcase your skills to
            recruiters.
          </h3>
          <img
            style={{
              width: "300px",
              margin: "10px 0px",
              height: "300px",
              objectFit: "contain",
            }}
            src="https://res.cloudinary.com/cqn/image/upload/v1742050003/new_certificate_kqhz2h.png"
            alt=""
          />
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h4 style={{ margin: "5px 0px" }}>Our Presence Spans</h4>
          <h2 style={{ margin: "10px 0px", textAlign: "center" }}>
            Students From Top Colleges Upskill with Acetechies Academy
          </h2>
          <h3
            style={{
              color: "#bbb6b6",
              margin: "10px 20px",
              fontSize: "18px",
              lineHeight: "30px",
              textAlign: "center",
            }}
          >
            Students Learn from us what is actually relevant in the industry.
          </h3>
          <MarqueeScroll content={collegeData} />
        </div>
      </div>
    );
  };

  const part3 = () => {
    return (
      <div style={{ margin: "0px 20px" }}>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            color: "#fff",
          }}
        >
          <h4 style={{ margin: "5px 0px" }}>FAQs</h4>
          <h2 style={{ margin: "0px" }}>Frequently Asked Questions</h2>
          <h3
            style={{
              color: "#bbb6b6",
              margin: "10px 20px",
              fontSize: "18px",
              lineHeight: "30px",
              textAlign: "center",
            }}
          >
            Have any doubts? Let's clear them below
          </h3>
        </div>
        {faqs.map((faq, index) => {
          return (
            <Accordion
              key={index}
              style={{
                marginBottom: "20px",
                backgroundColor: "rgb(63 40 112)",
                padding: "0px",
                borderRadius: "8px",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ color: "#fff", fontSize: "30px" }} />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  fontSize: "14px",
                  color: "#fff",
                  fontWeight: "600",
                  fontFamily: "Questrial",
                  lineHeight: "1.5",
                }}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>
                <h4 style={{ margin: "0px", color: "#fff", lineHeight: "1.5" }}>
                  {faq.answer}
                </h4>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const MobileLayout = () => (
    <div className="workshopdetails_mobile">
      {part1()}
      {part2()}
      {part3()}
    </div>
  );

  return (
    <div className="workshopdetails_main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bootcamp Details</title>
      </Helmet>
      <EducationTopBar
        logo={
          <img
            onClick={() => history.push("/")}
            style={{
              width: "40px",
              height: "40px",
              objectFit: "contain",
              cursor: "pointer",
            }}
            src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
            alt=""
          />
        }
      />
      <div style={{ marginTop: "80px" }} />
      {window.innerWidth >= 768 ? <DesktopLayout /> : <MobileLayout />}
      {isClicked && <JoinWorkshopModal open={isClicked} />}
      <WorkshopFooterPage />
    </div>
  );
}

export default WorkshopDetails;
