// Workshop.jsx
import React, { useState, useEffect, useRef } from "react";
import "./Workshop.css";
import EducationTopBar from "../topbar/EducationTopBar";
import { Avatar } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import MarqueeScroll from "../reusables/MarqueeScroll";
import {
  collegeData,
  companies,
  profileImages,
  workshopReviews,
} from "../../../utils/constants";
import WorkshopFooterPage from "./WorkshopFooter";
import { useDispatch, useSelector } from "react-redux";
import { getAllBootCampAction } from "../../../actions/BootCamp/bootcampActions";
import { formatDate } from "../../../utils/change_time_format";
import ReadMoreLess from "../reusables/ReadMoreLess";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MySkeletonLoader from "../reusables/MySkeletonLoader";
import DesktopPart1 from "./Desktop1";
import YouTube from "react-youtube";

function Workshop() {
  const desktopPart2Ref = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { bootCamps, loading } = useSelector((state) => state.bootCampStore);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(getAllBootCampAction());
  }, []);

  const handleCardClick = (id) => {
    history.push(`/workshop/details/${id}`);
  };

  // Mobile Version (Original)
  const mobileRegisteredUsers = () => (
    <div className="mobile-registered-users">
      {profileImages.map((item) => (
        <Avatar
          key={item}
          sx={{ width: "40px", height: "40px" }}
          src={item}
          className="mobile-avatar-overlap"
        />
      ))}
      <p className="mobile-registered-text">+100 Students Trained</p>
    </div>
  );

  const mobileWorkshopCard = (item) => {
    const date = formatDate(
      item?.dates?.startDate ?? "2025-03-02T00:00:00.000Z"
    );
    const alreadyRegistered = item?.registeredUsers?.includes(userInfo?._id);
    const media = item?.media?.length > 0 ? item?.media : null;
    return (
      <div
        onClick={() => handleCardClick(item?._id)}
        key={item?._id}
        className="mobile-workshop-card"
      >
        <div className="mobile-card-image-container">
          {media?.length > 0 && media[0]?.type === "image" ? (
            <img
              width={320}
              height={250}
              src={
                (item?.media?.length > 0 && item?.media[0]?.url) ||
                "https://plus.unsplash.com/premium_photo-1677094310919-d0361465d3be?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              alt=""
              className="mobile-workshop-image"
            />
          ) : (
            <div className="desktop-workshop-youtube-container">
              <YouTube
                videoId={media[0]?.url}
                opts={{
                  playerVars: {
                    autoplay: 0,
                  },
                }}
              />
            </div>
          )}
          <div className="mobile-date-overlay">
            <div className="mobile-date-content">
              <CalendarMonthIcon className="mobile-calendar-icon" />
              <h4 className="mobile-date-text">{date?.day}</h4>
            </div>
            <h4 className="mobile-month-text">
              {date?.month}, {date?.year}
            </h4>
          </div>
        </div>
        <div className="mobile-card-header">
          <div className="mobile-level-tag">{item?.level}</div>
          <div className="mobile-spots-info">
            <GroupsIcon className="mobile-groups-icon" />
            <h4 className="mobile-spots-text">
              {item?.spots?.available} Spots Left
            </h4>
          </div>
        </div>
        <h3 className="mobile-workshop-title">{item?.title}</h3>
        <p className="mobile-workshop-desc">
          <ReadMoreLess text={item?.description} maxLength={60} />
        </p>
        <div className="mobile-registered-preview">
          {profileImages.map((item) => (
            <Avatar
              key={item}
              sx={{ width: "30px", height: "30px" }}
              src={item}
              className="mobile-avatar-overlap-small"
            />
          ))}
          <h4 className="mobile-registered-count">
            +
            {item?.registeredUsers?.length >= 20
              ? item?.registeredUsers?.length
              : 20}{" "}
            Registered
          </h4>
        </div>
        <div className="mobile-card-footer">
          {alreadyRegistered ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                history.push("/dashboard");
              }}
              className="dashboard-btn"
            >
              Go to Dashboard
              <span className="arrow-icon">→</span>
            </button>
          ) : (
            <button className="mobile-shimmer-btn">Enroll Now</button>
          )}
          <p className="mobile-price-info">
            <span className="mobile-original-price">
              ₹{item?.price?.originalPrice}
            </span>
            <span className="mobile-separator">•</span>
            <span className="mobile-current-price">Free</span>
          </p>
        </div>
      </div>
    );
  };

  // Desktop Version (Enhanced)
  const desktopRegisteredUsers = () => (
    <div className="desktop-registered-users">
      {[1, 2, 3, 4, 5].map((item) => (
        <Avatar
          key={item}
          sx={{ width: "60px", height: "60px" }}
          src="https://images.unsplash.com/photo-1521566652839-697aa473761a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          className="desktop-avatar-overlap"
        />
      ))}
      <p className="desktop-registered-text">+100 Students Trained</p>
    </div>
  );

  const desktopWorkshopCard = (item) => {
    const date = formatDate(
      item?.dates?.startDate ?? "2025-03-02T00:00:00.000Z"
    );
    const alreadyRegistered = item?.registeredUsers?.includes(userInfo?._id);
    const media = item?.media?.length > 0 ? item?.media : null;
    return (
      <div
        onClick={() => handleCardClick(item?._id)}
        key={item?._id}
        className="desktop-workshop-card"
      >
        <div className="desktop-card-image-container">
          {media?.length > 0 && media[0]?.type === "image" ? (
            <img
              src={
                (item?.media?.length > 0 && item?.media[0]?.url) ||
                "https://plus.unsplash.com/premium_photo-1677094310919-d0361465d3be?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              alt=""
              className="desktop-workshop-image"
            />
          ) : (
            <div className="desktop-workshop-youtube-container">
              <YouTube
                videoId={media[0]?.url}
                opts={{
                  playerVars: {
                    autoplay: 0,
                  },
                }}
              />
            </div>
          )}
          <div className="desktop-date-overlay">
            <div className="desktop-date-content">
              <CalendarMonthIcon className="desktop-calendar-icon" />
              <h4 className="desktop-date-text">{date?.day}</h4>
            </div>
            <h4 className="desktop-month-text">
              {date?.month}, {date?.year}
            </h4>
          </div>
        </div>
        <div className="desktop-card-details">
          <div className="desktop-card-header">
            <div className="desktop-level-tag">{item?.level}</div>
            <div className="desktop-spots-info">
              <GroupsIcon className="desktop-groups-icon" />
              <h4 className="desktop-spots-text">
                {item?.spots?.available} Spots Left
              </h4>
            </div>
          </div>
          <h3 className="desktop-workshop-title">{item?.title}</h3>
          <p className="desktop-workshop-desc">
            <ReadMoreLess text={item?.description} maxLength={80} />
          </p>

          <div className="desktop-registered-preview">
            {profileImages.map((item) => (
              <Avatar
                key={item}
                sx={{ width: "40px", height: "40px" }}
                src={item}
                className="desktop-avatar-overlap-small"
              />
            ))}
            <h4 className="desktop-registered-count">
              +
              {item?.registeredUsers?.length >= 20
                ? item?.registeredUsers?.length
                : 20}{" "}
              Registered
            </h4>
          </div>
          <div className="desktop-card-footer">
            {alreadyRegistered ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/dashboard");
                }}
                className="dashboard-btn"
              >
                Go to Dashboard
                <span className="arrow-icon">→</span>
              </button>
            ) : (
              <button className="desktop-shimmer-btn">Enroll Now</button>
            )}
            <p className="desktop-price-info">
              <span className="desktop-original-price">
                ₹{item?.price?.originalPrice}
              </span>
              <span className="desktop-separator">•</span>
              <span className="desktop-current-price">Free</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const mobilePart1 = () => (
    <div className="mobile-part1">
      <h1 style={{ margin: "0px" }} className="mobile-hero-title">
        Live Workshop
      </h1>
      <h1 className="mobile-gradient-text">Join Now.</h1>
      <div className="mobile-workshop-subtitle">
        <h2 className="mobile-hero-subtitle">
          Master AI-Powered Web Apps. Learn ReactJS, Node.js & AI APIs with
          hands-on projects.
        </h2>
      </div>
      {mobileRegisteredUsers()}
      <div style={{ margin: "10px 0px 0px 0px" }} />
      <MarqueeScroll
        content={companies}
        width="60px"
        height="60px"
        margin="0px 30px"
      />
    </div>
  );

  const mobilePart2 = () => (
    <div className="mobile-workshop-part2">
      <div className="mobile-section-header">
        <img width={240} src="/images/line.png" alt="" />
        <h1 className="mobile-glitch">Upcoming Workshops</h1>
        <img width={200} src="/images/line.png" alt="" />
      </div>
      <div className="mobile-workshops-container">
        {loading ? (
          <MySkeletonLoader width={300} height={350} />
        ) : (
          bootCamps?.upcomingBootCamps?.map((item) => mobileWorkshopCard(item))
        )}
      </div>
    </div>
  );

  const desktopPart2 = () => (
    <div ref={desktopPart2Ref} className="desktop-workshop-part2">
      <div className="desktop-section-header">
        <img width={300} src="/images/line.png" alt="" />
        <h1 className="desktop-glitch">Upcoming Workshops</h1>
        <img width={300} src="/images/line.png" alt="" />
      </div>
      <div className="desktop-workshops-grid">
        {loading
          ? [1, 2, 3]?.map((_, index) => (
              <MySkeletonLoader key={index} width={350} height={500} />
            ))
          : bootCamps?.upcomingBootCamps?.map((item) =>
              desktopWorkshopCard(item)
            )}
      </div>
    </div>
  );

  const mobilePart3 = () => (
    <div className="mobile-part3">
      <div className="mobile-section-header">
        <img width={220} src="/images/line.png" alt="" />
        <h1 className="mobile-glitch">Next Workshops</h1>
        <img width={180} src="/images/line.png" alt="" />
      </div>
      <div className="mobile-workshop-carousal">
        {loading
          ? [1, 2, 3]?.map((_, index) => (
              <div key={index} style={{ margin: "0px 5px" }}>
                <MySkeletonLoader width={300} height={300} />{" "}
              </div>
            ))
          : bootCamps?.nextBootCamps?.map((item) => mobileWorkshopCard(item))}
      </div>
      <div className="mobile-colleges-section">
        <h1 className="mobile-section-title">Colleges</h1>
        <h3 className="mobile-section-subtitle">
          Students from Top Universities Who Have Joined Us
        </h3>
        <MarqueeScroll content={collegeData} margin="0px 20px" width="200px" />
      </div>
      <div className="mobile-certificates-section">
        <h1 className="mobile-section-title">Certificates</h1>
        <h3 className="mobile-section-subtitle">
          Earn certificates for each workshop and showcase your skills to
          recruiters.
        </h3>
        <img
          className="mobile-certificate-image"
          src="./images/new_certificate.png"
          alt=""
        />
      </div>
      <div className="mobile-reviews-section">
        <h1 className="mobile-section-title">Reviews</h1>
        <h3 className="mobile-section-subtitle">
          What our students have to say about us.
        </h3>
        <MarqueeScroll content={workshopReviews} isReview={true} />
      </div>
    </div>
  );

  const desktopPart3 = () => (
    <div className="desktop-part3">
      <div className="desktop-section-header">
        <img width={280} src="/images/line.png" alt="" />
        <h1 className="desktop-glitch">Next Workshops</h1>
        <img width={280} src="/images/line.png" alt="" />
      </div>
      <div className="desktop-workshops-grid">
        {loading
          ? [1, 2, 3]?.map((_, index) => (
              <MySkeletonLoader key={index} width={350} height={500} />
            ))
          : bootCamps?.nextBootCamps?.map((item) => desktopWorkshopCard(item))}
      </div>
      <div className="desktop-colleges-section">
        <h1 className="desktop-section-title">Colleges</h1>
        <h3 className="desktop-section-subtitle">
          Students from Top Universities Who Have Joined Us
        </h3>
        <MarqueeScroll content={collegeData} margin="0px 60px" />
      </div>
      <div className="desktop-certificates-section">
        <h1 className="desktop-section-title">Certificates</h1>
        <h3 className="desktop-section-subtitle">
          Earn industry-recognized certificates for each workshop and boost your
          career.
        </h3>
        <div className="desktop-certificates-container">
          <div className="desktop-certificates-text">
            <h4 className="certificates-highlight">Unlock Your Potential</h4>
            <p className="certificates-desc">
              Complete our workshops to earn certificates that showcase your
              skills to top recruiters. Whether you're a beginner or an advanced
              learner, we have a certification path for you!
            </p>
            <button
              onClick={() => {
                desktopPart2Ref.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              className="desktop-cta-btn primary"
            >
              Enroll Now
            </button>
          </div>
          <div className="desktop-certificates-image">
            <img
              className="desktop-certificate-image"
              src="./images/new_certificate.png"
              alt="Workshop Certificate"
            />
          </div>
        </div>
      </div>
      <div className="desktop-reviews-section">
        <h1 className="desktop-section-title">Reviews</h1>
        <h3 className="desktop-section-subtitle">
          What our students have to say about us.
        </h3>
        <MarqueeScroll content={workshopReviews} isReview={true} />
      </div>
    </div>
  );

  return (
    <div className="workshop_main">
      <EducationTopBar
        logo={
          <div
            onClick={() => history.push("/")}
            style={{ display: "flex", alginItems: "center", cursor: "pointer" }}
          >
            <img
              className={isMobile ? "mobile-logo" : "desktop-logo"}
              src="https://res.cloudinary.com/cqn/image/upload/v1740519381/white_acetechies_logo_vsdhp5.png"
              alt=""
            />
          </div>
        }
      />

      {isMobile ? (
        <div>
          {mobilePart1()}
          {mobilePart2()}
          {mobilePart3()}
        </div>
      ) : (
        <>
          {<DesktopPart1 desktopPart2Ref={desktopPart2Ref} />}
          {desktopPart2()}
          {desktopPart3()}
        </>
      )}
      <WorkshopFooterPage />
    </div>
  );
}

export default Workshop;
