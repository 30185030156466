import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer, userRegisterReducer } from "./reducers/userReducers";
import {
  coursePaymentReducer,
  getSelectedCourseDataReducer,
  getSuggestedCoursesReducer,
  selectedCourseFromListReducer,
  selectedExploreCourseReducer,
} from "./reducers/Education/courses_reducer";
import {
  certificateEnrollmentReducer,
  certificateProblemStatementSelectedReducer,
  getCertificateFilteredProblemStatementReducer,
  getCertificateReducer,
  redirectionPageReducer,
} from "./reducers/Education/certificate_reducer";
import {
  dashboardFilterCertificationsReducer,
  dashboardGetAllSubmissionReducer,
  dashboardUploadGithublinkReducer,
  dashboardUploadSolutionReducer,
} from "./reducers/Dashboard/dashboard_reducer";
import {
  completeUserSubmissionReducer,
  getAllSubmittedUsersReducer,
} from "./reducers/Admin-Panel/adminPanelReducers";
import {
  allWebinarEventReducer,
  getMyRegisteredEventsReducer,
  registerUserForWebinarReducer,
  upcomingWebinarEventReducer,
  webinarEventComingFromReducer,
} from "./reducers/WebinarEvents/webinarEventReducers";
import {
  bootCampByIdReducer,
  bootcampConfirmTimeSlotReducer,
  bootcampGetMyPaymentsReducer,
  bootcampPaymentReducer,
  bootCampReducer,
  getRegisteredBootCampsReducer,
  joinBootCampReducer,
} from "./reducers/BootCamp/bootcamp_reducer";
// import { tempSaveBookingReducer } from "./reducers/Booking/bookingReducers";

const reducer = combineReducers({
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  selectedExploreCourse: selectedExploreCourseReducer,
  selectedCourseFromListStore: selectedCourseFromListReducer,
  coursesFromServer: getSelectedCourseDataReducer,
  suggestedCoursesListStore: getSuggestedCoursesReducer,
  certificateProblemStatements: certificateProblemStatementSelectedReducer,
  getCertificateStore: getCertificateReducer,
  getCertificateFilteredProblemStatementStore:
    getCertificateFilteredProblemStatementReducer,
  redirectUrlStore: redirectionPageReducer,
  certificateEnrollmentStore: certificateEnrollmentReducer,
  dashboardFilterCertificationsStore: dashboardFilterCertificationsReducer,
  dashboardUploadSolutionStore: dashboardUploadSolutionReducer,
  dashboardUploadGithublinkStore: dashboardUploadGithublinkReducer,
  getAllSubmittedUsersStore: getAllSubmittedUsersReducer,
  usersAllSubmissionsStore: dashboardGetAllSubmissionReducer,
  completeUserSubmissionStore: completeUserSubmissionReducer,
  allWebinarEventStore: allWebinarEventReducer,
  upcomingEventStore: upcomingWebinarEventReducer,
  registerUserForWebinarStore: registerUserForWebinarReducer,
  webinarEventComingFromStore: webinarEventComingFromReducer,
  getMyRegisteredEventsStore: getMyRegisteredEventsReducer,
  coursePaymentStore: coursePaymentReducer,
  bootCampStore: bootCampReducer,
  bootCampByIdStore: bootCampByIdReducer,
  joinBootCampStore: joinBootCampReducer,
  getRegisteredBootCampsStore: getRegisteredBootCampsReducer,
  confirmTimeSlotStore: bootcampConfirmTimeSlotReducer,
  bootcampPaymentStore: bootcampPaymentReducer,
  bootcampGetMyPaymentsStore: bootcampGetMyPaymentsReducer,
});

const userInfoFromStorage = localStorage.getItem("AceTech-UserInfo")
  ? JSON.parse(localStorage.getItem("AceTech-UserInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export { store };
