export class ImageConstants {
  static main_logo =
    "https://res.cloudinary.com/cqn/image/upload/v1708703521/AceTech_2_fmqpw4.png";
  static courses_page_logo =
    "https://res.cloudinary.com/cqn/image/upload/v1710679794/AceTechLogo_jmww7n.png";
  static realworld_icon =
    "https://res.cloudinary.com/cqn/image/upload/v1708703547/programming_feaiej.png";

  static certificate_icon =
    "https://res.cloudinary.com/cqn/image/upload/v1708703552/certificate_pkv390.png";
  static flexible_time_icon =
    "https://res.cloudinary.com/cqn/image/upload/v1708703579/timetable_ab8nx9.png";

  static my_semi_circle =
    "https://res.cloudinary.com/cqn/image/upload/v1708703507/circle_fg0hfw.png";

  static video_about_company =
    "https://cdn.discordapp.com/attachments/1107210971124080643/1204478637294747759/about_company_video.mp4?ex=65d4e125&is=65c26c25&hm=d5469c5b2531a73a7c993af575e2280dcc25853826cd13ea82ccbc2551a051c9&";

  static about_company_girl_image =
    "https://cdn.discordapp.com/attachments/1107210971124080643/1206182667465261107/animated_background_video.mp4?ex=65db1425&is=65c89f25&hm=62e83838982765ef60d8cc9ad33533876c6eea2cdb0a98fdf432fced33f2e3cd&";

  static commas_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708703584/commas_t4nbnl.png";

  static instagram_project_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708703604/instagram_project_rfpgk7.png";
  static video_project_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708703615/video_project_image_mjgkll.png";
  static shopping_project_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708703619/shopping_project_image_c4z7ar.png";
  static distribute_project_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708703643/distribution_project_image_keplmu.png";
  static profile_image_1 =
    "https://res.cloudinary.com/cqn/image/upload/v1716064808/aditya_shakya_frsjs0.png";
  static profile_image_2 =
    "https://res.cloudinary.com/cqn/image/upload/v1716064764/tejas_shelar_gjvtl1.png";
  static profile_image_3 =
    "https://res.cloudinary.com/cqn/image/upload/v1716064656/snehal_more_ldefb4.png";
  static logo_image =
    "https://cdn.discordapp.com/attachments/1107210971124080643/1201925254868369469/AceTech_2.png?ex=65cb971f&is=65b9221f&hm=7eb1e7d0d10dc9c717979aa5064d012d34e2edbce53f3643976970a92b4dc60c&";
  static login_page_image1 =
    "https://res.cloudinary.com/cqn/image/upload/v1716725568/login_image_1_e62kvq.png";
  static login_page_image2 =
    "https://res.cloudinary.com/cqn/image/upload/v1716739519/login_image_2_ndljjv.png";
  static login_page_image3 =
    "https://res.cloudinary.com/cqn/image/upload/v1716739790/login_image_3_xrvbnr.png";
  static signUp_page_image =
    "https://res.cloudinary.com/cqn/image/upload/v1708977112/signup_page_image_vydtgs.png";
  static courses_certificate_image =
    "https://res.cloudinary.com/cqn/image/upload/v1709662683/certificate_courses_page_okepdp.png";
  static getUserProfile_image =
    "https://images.unsplash.com/photo-1524860769472-246b6afea403?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  static getCertificatesListImage =
    "https://res.cloudinary.com/cqn/image/upload/v1710706125/acetech_certificates_list_qhhsup.png";

  static getBadgeImage =
    "https://res.cloudinary.com/cqn/image/upload/v1710783594/gold-seal-badge-11546974016ucwnwtduvc-removebg-preview_dntnxq.png";
  static getIncreasingRevenue =
    "https://res.cloudinary.com/cqn/image/upload/v1710784483/increasing_revenue_cua0nf.png";
  static getRecruiterImage =
    "https://res.cloudinary.com/cqn/image/upload/v1710784674/recruiter_buvs4f.png";
  static getLinesImage =
    "https://res.cloudinary.com/cqn/image/upload/v1710785800/lines_o4p0kv.png";
  static getBasicCertificateImage =
    "https://res.cloudinary.com/cqn/image/upload/v1711196127/fullstack_certificate_jr4kq3.png";
  static getIntermediateCertificateImage =
    "https://res.cloudinary.com/cqn/image/upload/v1711196127/fullstack_certificate_jr4kq3.png";
  static getAdvancedCertificateImage =
    "https://res.cloudinary.com/cqn/image/upload/v1711196127/fullstack_certificate_jr4kq3.png";
  static getAvatarImage =
    "https://images.unsplash.com/photo-1524860769472-246b6afea403?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  static getTempImage =
    "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  static getDashboardLogo =
    "https://res.cloudinary.com/cqn/image/upload/v1712085811/white_acetechies_logo_wtuabw.png";

  static getDashboardTopLogo =
    "https://res.cloudinary.com/cqn/image/upload/v1712160770/dashboard_top_image_zqi0or.png";
  static getDashboardCertifcateTopImage =
    "https://res.cloudinary.com/cqn/image/upload/v1712420695/dashboard_certificate_top_image_e8szvz.png";
  static getDashboardCoursesTopImage =
    "https://res.cloudinary.com/cqn/image/upload/v1712430182/dashboard_courses_image_rtstgo.png";
  static getStaticProblemStatementImage =
    "https://cdn.dribbble.com/userupload/8587680/file/original-2746ea57df22f4e217d4eabb3ad2bd2a.jpeg?resize=1200x900";
  static getNotFoundImage =
    "https://res.cloudinary.com/cqn/image/upload/v1712848631/nothing_found_bgmrvo.png";
  static getSupportImage =
    "https://res.cloudinary.com/cqn/image/upload/v1712946858/support_background_image_d95pan.png";
  static getLiveIcon =
    "https://res.cloudinary.com/cqn/image/upload/v1714247844/live_cspbb8.png";
}

export class StringConstants {
  static realworld_subtitleString =
    "Create real-world applications, boost your resume and land jobs in companies offering more than 8+LPA";
  static certification_string =
    "Acquire our frontend, backend, and fullstack certificates to excel in interviews and land high-paying jobs.";
  static flexible_string =
    "Students can view videos according to their flexibility and learn and create applications";
  static our_program_card1 =
    "Create Projects that will standout your resume and get the call from companies. We help students create projects which includes Playing ads in between videos?, Creating a instagram like algorithm and show similar image feed and reels?, Create in video shopping commerce and much more...";
  static our_program_card2 =
    "Elevate your resume with our frontend, backend, and fullstack certifications. Validate your expertise and stand out in the tech industry. Gain specialized skills that open doors to exciting career opportunities. Take your professional profile to the next level with us.";

  static our_program_card3 =
    "Access curated video playlists and master end-to-end frontend, backend, and fullstack projects with ease. Our comprehensive tutorials empower students to develop robust skills across the tech spectrum, ensuring readiness for real-world challenges and career success.";

  static tejas_comment =
    "Grateful for Acetech's exceptional course videos, enabling me to craft standout projects, enhancing my resume. Their guidance has been invaluable for my professional journey.";
  static aditya_comment =
    "I'm grateful for Acetech's exceptional course videos, which have enhanced my projects and resume. Their invaluable guidance has propelled my professional journey.";
  static snehal_comment =
    "Thanks to Acetech's exceptional course videos, I've crafted standout projects and boosted my resume. Their guidance has been invaluable for my professional journey, and I'm truly grateful for their support.";

  static certifyNow = "Certify now, boost interview chances by 78%!";
  static revenueBoost = "Certify now, Get 8+ LPA job offers!";
  static recruiterSearch = "Certify now, Get Interview calls from big MNCs.";
  static stepperStep1String =
    "Select the problem from the list of problem statement. Requirements are listed for each problem statement along with videos or images of expected results.";
  static stepperStep2String =
    "Once completed, add your solution to github(we have shared how to add your solution to github) and share the link with us.";
  static stepperStep3String =
    "We will review and share your certificate on your email.";
}

export class ExternalLinks {
  static linkedIn = "https://www.linkedin.com/in/acetechies-academy-165b282b3/";
}

export class MoneySigns {
  static indianRupees = "₹";
}

export const collegeData = [
  {
    id: 1,
    src: "https://medha.org.in/user-content/media-versions/1400w_x2400h__q_90_c_Navgurukul.jpg",
    name: "Navgurukul",
  },
  {
    id: 2,
    src: "https://d2lk14jtvqry1q.cloudfront.net/media/large_177_35e5fdfc68_7f73ce2c67.png",
    name: "Indira Gandhi Technical University for Women",
  },
  {
    id: 3,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzPKlZeJZBWaQFzyzbQxPxzE_5WqVx5jZJ6Q&s",
    name: "Nutan College of Engineering and Research ",
  },
  {
    id: 1,
    src: "https://almashines.s3.dualstack.ap-southeast-1.amazonaws.com/assets/images/institutes/logo/170x170/949.jpg?v=1655352495",
    name: "Madhav Institute of Technology and Science ",
  },
];

export const workshopReviews = [
  {
    id: 1,
    review:
      "Acetech's Live Workshop was awesome! Helped me build cool projects and boost my resume. Really thankful for their guidance.",
    fullName: "Shivam Shrivastava",
    profileImage: ImageConstants.profile_image_1,
    stars: 5,
  },
  {
    id: 2,
    review:
      "Loved Acetech's workshop! Made some solid projects and improved my resume. Their tips were super helpful.",
    fullName: "Vishal Sachdev",
    profileImage: ImageConstants.profile_image_2,
    stars: 4,
  },
  {
    id: 3,
    review:
      "Acetech's workshop was a game-changer. Built great projects, and my resume looks better now. Grateful for their support!",
    fullName: "Vedashree Bhawsar",
    profileImage: ImageConstants.profile_image_3,
    stars: 4,
  },
  {
    id: 4,
    review:
      "Superb experience with Acetech's workshop! Learned a lot, made projects, and got good career advice.",
    fullName: "Priya Sharma",
    profileImage: ImageConstants.profile_image_4,
    stars: 5,
  },
  {
    id: 5,
    review:
      "Acetech's Live Workshop was too good! Helped me with projects and resume. Really worth it.",
    fullName: "Rohan Patil",
    profileImage: ImageConstants.profile_image_5,
    stars: 4,
  },
  {
    id: 6,
    review:
      "Had fun at Acetech's workshop! Built some nice projects and got useful guidance for my career.",
    fullName: "Neha Gupta",
    profileImage: ImageConstants.profile_image_6,
    stars: 5,
  },
  {
    id: 7,
    review:
      "Acetech's workshop was amazing yaar! Projects turned out well, and my resume looks stronger now.",
    fullName: "Aakash Verma",
    profileImage: ImageConstants.profile_image_7,
    stars: 4,
  },
  {
    id: 8,
    review:
      "Thanks to Acetech's workshop, I made decent projects and learned a lot. Helped me level up!",
    fullName: "Sneha Kulkarni",
    profileImage: ImageConstants.profile_image_8,
    stars: 5,
  },
];

export const companies = [
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804276/Amazon-Logo-2000-present-1024x576-removebg-preview_gvtwgl.png",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804276/images-removebg-preview_wehb6a.png",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804276/Capgemini-Logo-removebg-preview_hkjujv.png",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804276/images-removebg-preview_1_tmdqwk.png",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804275/Meesho-682x435-removebg-preview_e8tvkj.png",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1741804275/RWCZER-Legal-IP-Trademarks-CP-MS-logo-740x417-1-removebg-preview_e72dsp.png",
  },
];

export const faqs = [
  {
    question: "What is the duration of the Workshop/Bootcamp?",
    answer: "The Workshop/Bootcamp duration usually are of 3 to 5 Days.",
  },
  {
    question: "Is there any specific software I need to use?",
    answer: "There are no extra softwares that are needed to be installed.",
  },
  {
    question: "Will I receive a certificate upon completion?",
    answer: "Yes, all Programs provide a certificate upon completion.",
  },
  {
    question: "In what platform will the classes be hosted?",
    answer: "Classes will be hosted LIVE on Google Meet.",
  },
  {
    question: "Will there be any projects covered in the Workshop/Bootcamp?",
    answer: "Yes, each Workshop/Bootcamp has a project that will be covered.",
  },
];

export const profileImages = [
  "https://i.pinimg.com/736x/a9/2b/69/a92b69934e0a8391ac72df6fcac30254.jpg",
  "https://i.pinimg.com/736x/7d/1a/d3/7d1ad387d369b8586eb01ab5b247dfbf.jpg",
  "https://i.pinimg.com/736x/bd/a7/62/bda762d92fb045b0ba5d0d6e00668a78.jpg",
  "https://i.pinimg.com/736x/f2/fa/a4/f2faa44cc72d5d11d59f031962f3c7bc.jpg",
];
