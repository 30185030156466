import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal"; // Assuming you're using Material-UI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./JoinWorkshopModal.css"; // You'll need to create this CSS file
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Add this new component for the changing text
const ChangingText = () => {
  const messages = [
    "Booking your spot in the workshop",
    "Initializing AI learning modules",
    "Preparing your dashboard access",
    "Syncing workshop materials",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 1500); // Change message every 1.5 seconds

    return () => clearInterval(interval);
  }, []);

  return <p className="changing-text">{messages[currentMessageIndex]}</p>;
};
export const JoinWorkshopModal = ({ open, bootcampId }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [joinSuccess, setJoinSuccess] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      // Simulate API call
      const timer = setTimeout(() => {
        setIsLoading(false);
        setJoinSuccess(true);
      }, 2000); // 2 second delay to show loading

      return () => clearTimeout(timer);
    }
  }, [open]);

  useEffect(() => {
    if (joinSuccess) {
      const redirectTimer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(redirectTimer);
            handleDashboardRedirect();
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Decrease countdown every second

      return () => clearInterval(redirectTimer);
    }
  }, [joinSuccess]);

  const handleDashboardRedirect = () => {
    // Add your dashboard redirection logic here
    history.push("/dashboard");
  };

  // In JoinModal component, replace the aiLoader constant with this:
  const aiLoader = (
    <div className="ai-loader-container">
      <div className="ai-orbit-container">
        <div className="ai-orbit-ring ring-1"></div>
        <div className="ai-orbit-ring ring-2"></div>
        <div className="ai-orbit-ring ring-3"></div>
        <div className="ai-core">
          <div className="ai-core-pulse"></div>
        </div>
      </div>
      <div className="ai-text-container">
        <p className="loading-text">Securing Your Bootcamp Spot</p>
        <ChangingText />
      </div>
    </div>
  );

  const successContent = (
    <div className="success-content">
      <h2 className="success-title">Success! 🚀</h2>
      <div className="success-tick">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle
            cx="30"
            cy="30"
            r="28"
            stroke="#9333ea"
            strokeWidth="4"
            fill="none"
          />
          <path
            d="M20 30 L27 37 L40 24"
            stroke="#9333ea"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <p className="success-message">
        You've successfully joined the bootcamp!
      </p>
      <p className="countdown-text">
        Redirecting to dashboard in {countdown} seconds...
      </p>
      <Button
        variant="contained"
        onClick={handleDashboardRedirect}
        sx={{
          backgroundColor: "#8a4af5", // Purple
          color: "#fff",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#6b2ed9", // Darker purple on hover
          },
        }}
      >
        Go to Dashboard
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      aria-labelledby="join-modal-title"
      aria-describedby="join-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 300, md: 500 }, // Responsive width
          maxWidth: "80vw", // Prevent overflow on small screens
          bgcolor: "#1a1a1a",
          borderRadius: "12px",
          boxShadow: 24,
          p: { xs: 4, md: 6 }, // Responsive padding
          color: "#fff",
        }}
      >
        {isLoading ? aiLoader : successContent}
      </Box>
    </Modal>
  );
};
