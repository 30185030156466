import React, { useState, useEffect, useRef } from "react";
import MarqueeScroll from "../reusables/MarqueeScroll";
import { companies } from "../../../utils/constants";
import "./Desktop1.css";

const DesktopPart1 = ({ desktopPart2Ref }) => {
  const [titleText, setTitleText] = useState("Live Bootcamp"); // Keep this for initial state
  const titles = ["Live Bootcamp", "Live Workshops", "Hands-on Coding"];
  const [titleIndex, setTitleIndex] = useState(0);
  const [displayedLines, setDisplayedLines] = useState(Array(17).fill(""));
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const animationRef = useRef(null);
  const animationCompleteRef = useRef(false);

  // Cyclic fade effect (replacing typing effect)
  useEffect(() => {
    const interval = setInterval(() => {
      setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
      setTitleText(titles[titleIndex]); // Directly set the title
    }, 3000);
    return () => clearInterval(interval);
  }, [titleIndex]); // Add titleIndex as dependency to update titleText correctly

  // Remove the second useEffect for typing since we're using fade now
  // The code snippet animation remains unchanged
  useEffect(() => {
    if (animationCompleteRef.current) return;

    const runFastCodeAnimation = async () => {
      const charDelay = 10;
      const lineDelay = 100;

      setDisplayedLines(Array(codeLines.length).fill(""));

      for (let lineIndex = 0; lineIndex < codeLines.length; lineIndex++) {
        const line = codeLines[lineIndex];
        setCurrentLineIndex(lineIndex);

        for (let charIndex = 0; charIndex <= line.length; charIndex++) {
          setCurrentCharIndex(charIndex);
          setDisplayedLines((prev) => {
            const newLines = [...prev];
            newLines[lineIndex] = line.slice(0, charIndex);
            return newLines;
          });

          await new Promise((resolve) => setTimeout(resolve, charDelay));
        }

        if (lineIndex < codeLines.length - 1) {
          await new Promise((resolve) => setTimeout(resolve, lineDelay));
        }
      }

      animationCompleteRef.current = true;

      setTimeout(() => {
        setCurrentLineIndex(-1);
        setCurrentCharIndex(-1);
      }, 1000);
    };

    runFastCodeAnimation();

    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);

  const scrollToPart2 = () => {
    desktopPart2Ref.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  // Code snippet lines (unchanged)
  const codeLines = [
    "import React, { useState } from 'react';",
    "const AIApp = () => {",
    "  const [data, setData] = useState('');",
    "  const getAI = () => setData('AI Response');",
    "  return (",
    "    <div>{data || <button onClick={getAI}>Run AI</button>}</div>",
    "  );",
    "};",
    "export default AIApp;",
  ];

  return (
    <div className="desktop-hero">
      <div className="desktop-hero-split">
        {/* Left Side: Text and CTAs */}
        <div className="desktop-hero-left">
          <h1 className="desktop-hero-title">
            <span className="static-text">Upskill with </span>
            <span className="fade-text">{titles[titleIndex]}</span>{" "}
          </h1>
          <p className="desktop-hero-subtitle">
            Build AI-powered web apps using ReactJS, Node.js, and AI APIs.
            Perfect for students and professionals.
          </p>
          <div className="desktop-cta-container">
            <button
              onClick={() => scrollToPart2()}
              className="desktop-cta-btn primary"
            >
              Join Now
            </button>
          </div>
          <div className="desktop-stats-bar">
            <span>100+ Students Trained</span>
            <span className="divider_line">|</span>
            <span>50+ Projects Built</span>
          </div>
        </div>

        {/* Right Side: Animated Code Snippet */}
        <div className="desktop-hero-right">
          <div className="desktop-code-block">
            <pre className="code-content">
              {codeLines.map((_, index) => (
                <span key={index} className="code-line">
                  {displayedLines[index] || ""}
                  {currentLineIndex === index &&
                    currentCharIndex === displayedLines[index].length && (
                      <span className="code-cursor">|</span>
                    )}
                  {"\n"}
                </span>
              ))}
            </pre>
          </div>
        </div>
      </div>

      {/* Companies Marquee */}
      <div className="desktop-companies-marquee">
        <MarqueeScroll
          content={companies}
          width="60px"
          height="60px"
          margin="0px 80px"
        />
      </div>

      {/* Scroll Indicator */}
      <div className="scroll-indicator">
        <p>Discover Workshops</p>
        <div className="scroll-arrow">↓</div>
      </div>
    </div>
  );
};

export default DesktopPart1;
