export const WEBINAR_EVENT_ALL_LOADING = "WEBINAR_EVENT_ALL_LOADING";
export const WEBINAR_EVENT_ALL_SUCCESS = "WEBINAR_EVENT_ALL_SUCCESS";
export const WEBINAR_EVENT_ALL_FAIL = "WEBINAR_EVENT_ALL_FAIL";

export const REGISTER_USER_FOR_WEBINAR_LOADING =
  "REGISTER_USER_FOR_WEBINAR_LOADING";
export const REGISTER_USER_FOR_WEBINAR_SUCCESS =
  "REGISTER_USER_FOR_WEBINAR_SUCCESS";
export const REGISTER_USER_FOR_WEBINAR_FAIL = "REGISTER_USER_FOR_WEBINAR_FAIL";
export const REGISTER_USER_FOR_WEBINAR_RESET =
  "REGISTER_USER_FOR_WEBINAR_RESET";

export const GET_MY_REGISTERED_EVENTS_LOADING =
  "GET_MY_REGISTERED_EVENTS_LOADING";
export const GET_MY_REGISTERED_EVENTS_SUCCESS =
  "GET_MY_REGISTERED_EVENTS_SUCCESS";
export const GET_MY_REGISTERED_EVENTS_FAIL = "GET_MY_REGISTERED_EVENTS_FAIL";

export const WEBINAR_EVENT_TOP_RESULT = "WEBINAR_EVENT_TOP_RESULT";
export const WEBINAR_EVENT_UPCOMING = "WEBINAR_EVENT_UPCOMING";
export const WEBINAR_EVENT_POPULAR = "WEBINAR_EVENT_POPULAR";
export const GET_DETAILS_FOR_EVENT = "GET_DETAILS_FOR_EVENT";
export const REDIRECT_WEBINAR_EVENT_PAGE = "REDIRECT_WEBINAR_EVENT_PAGE";
export const REDIRECT_BOOTCAMP_BYID_PAGE = "REDIRECT_BOOTCAMP_BYID_PAGE";
export const WEBINAR_EVENT_USER_REGISTERED = "WEBINAR_EVENT_USER_REGISTERED";
export const CLEAR_REDIRECT_DATA = "CLEAR_REDIRECT_DATA";
