import React, { useState } from "react";

const ReadMoreLess = ({ text = "", maxLength, color }) => {
  const [expanded, setExpanded] = useState(text?.length < maxLength);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  // Inline styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
    lineHeight: "1.6",
    color: `${color || "#333"}`,
    margin: "1px 0",
  };

  const buttonStyle = {
    background: "none",
    border: "none",
    color: "rgb(96, 64, 238)",
    fontSize: "12px",
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft: "1px",
    textDecoration: "underline",
    transition: "color 0.2s ease-in-out",
  };

  const buttonHoverStyle = {
    color: "rgb(96, 64, 238)",
  };

  return (
    <span style={containerStyle}>
      <span>
        {expanded ? text : `${text.slice(0, maxLength)}...`}
        {text?.length > maxLength && (
          <button
            onClick={toggleReadMore}
            style={buttonStyle}
            onMouseOver={(e) => (e.target.style.color = buttonHoverStyle.color)}
            onMouseOut={(e) => (e.target.style.color = buttonStyle.color)}
          >
            {expanded ? "Read Less" : "Read More"}
          </button>
        )}
      </span>
    </span>
  );
};

export default ReadMoreLess;
