import {
  CERTIFICATE_ENROLLMENT_FAIL,
  CERTIFICATE_ENROLLMENT_LOADING,
  CERTIFICATE_ENROLLMENT_RESET,
  CERTIFICATE_ENROLLMENT_SUCCESS,
  CERTIFICATE_PROBLEM_STATEMENT_SELECTED,
  GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT,
  GET_CERTIFICATE_REQUEST_FAILED,
  GET_CERTIFICATE_REQUEST_LOADING,
  GET_CERTIFICATE_REQUEST_SUCCESS,
  REDIRECT_CERTIFICATE_PAGE,
} from "../../constants/Education/certificateConstants";
import {
  CLEAR_REDIRECT_DATA,
  REDIRECT_BOOTCAMP_BYID_PAGE,
  REDIRECT_WEBINAR_EVENT_PAGE,
} from "../../constants/WebinarEvents/webinarEventConstants";

export const certificateProblemStatementSelectedReducer = (
  state = { problemStatement: "problem1" },
  action
) => {
  if (action.type === CERTIFICATE_PROBLEM_STATEMENT_SELECTED) {
    return {
      ...state,
      problemStatement: action.payload,
    };
  } else {
    return state;
  }
};

export const getCertificateReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CERTIFICATE_REQUEST_LOADING:
      return {
        loading: true,
      };
    case GET_CERTIFICATE_REQUEST_SUCCESS:
      return {
        loading: false,
        success: true,
        certificates: action.payload,
      };
    case GET_CERTIFICATE_REQUEST_FAILED:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getCertificateFilteredProblemStatementReducer = (
  state = {},
  action
) => {
  if (action.type === GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT) {
    return {
      problemStatement: action.payload,
    };
  }
  return state;
};

export const redirectionPageReducer = (state = { redirectUrl: {} }, action) => {
  switch (action.type) {
    case REDIRECT_CERTIFICATE_PAGE:
      return {
        redirectUrl: action.payload,
      };
    case REDIRECT_WEBINAR_EVENT_PAGE:
      return {
        redirectUrl: action.payload,
      };
    case REDIRECT_BOOTCAMP_BYID_PAGE:
      return {
        redirectUrl: action.payload,
      };
    case CLEAR_REDIRECT_DATA:
      return {
        redirectUrl: {},
      };

    default:
      return state;
  }
};

export const certificateEnrollmentReducer = (state = {}, action) => {
  switch (action.type) {
    case CERTIFICATE_ENROLLMENT_LOADING:
      return {
        loading: true,
      };
    case CERTIFICATE_ENROLLMENT_SUCCESS:
      return {
        loading: false,
        success: true,
        enrolledCertificateUser: action.payload,
      };
    case CERTIFICATE_ENROLLMENT_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case CERTIFICATE_ENROLLMENT_RESET:
      return {
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};
