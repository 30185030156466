import {
  BOOTCAMP_CONFIRM_TIMESLOT_FAILED,
  BOOTCAMP_CONFIRM_TIMESLOT_LOADING,
  BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS,
  BOOTCAMP_GET_MY_PAYMENTS_FAILED,
  BOOTCAMP_GET_MY_PAYMENTS_LOADING,
  BOOTCAMP_GET_MY_PAYMENTS_SUCCESS,
  BOOTCAMP_PAYMENT_FAILED,
  BOOTCAMP_PAYMENT_INITIATION,
  BOOTCAMP_PAYMENT_SUCCESS,
  GET_ALL_BOOTCAMP_FAILURE,
  GET_ALL_BOOTCAMP_REQUEST,
  GET_ALL_BOOTCAMP_SUCCESS,
  GET_BOOTCAMP_BY_ID_FAILURE,
  GET_BOOTCAMP_BY_ID_REQUEST,
  GET_BOOTCAMP_BY_ID_SUCCESS,
  GET_REGISTERED_BOOTCAMPS_FAILURE,
  GET_REGISTERED_BOOTCAMPS_REQUEST,
  GET_REGISTERED_BOOTCAMPS_SUCCESS,
  JOIN_BOOTCAMP_FAILURE,
  JOIN_BOOTCAMP_REQUEST,
  JOIN_BOOTCAMP_SUCCESS,
} from "../../constants/BootCamp/bootCampConstants";
import { store } from "../../store";
import { APIRequestURI } from "../../utils/api_request_uri";
import axios from "axios";

export const getAllBootCampAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BOOTCAMP_REQUEST });

    const URI = new APIRequestURI().getBaseURI();
    const response = await fetch(`${URI}/api/bootcamp`);
    const data = await response.json();

    dispatch({
      type: GET_ALL_BOOTCAMP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BOOTCAMP_FAILURE,
      payload: error,
    });
  }
};

const checkPaymentStatus = (data, bootCampPaymentCheck) => {
  const bootCampPayment = data?.paidUsers?.find(
    (bootCamp) => bootCamp?._id === bootCampPaymentCheck.bootCampId
  );
  if (bootCampPayment) {
    if (bootCampPayment.bootCampPaymentStatus === "paid") {
      store.dispatch({
        type: BOOTCAMP_PAYMENT_SUCCESS,
        payload: bootCampPayment,
      });
    } else {
      store.dispatch({
        type: BOOTCAMP_PAYMENT_FAILED,
        payload: bootCampPayment,
      });
    }
  }
  localStorage.removeItem("acetechies_bootcamp_payment");
};

export const getBootCampByIdAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_BOOTCAMP_BY_ID_REQUEST });
    const URI = new APIRequestURI().getBaseURI();
    const response = await fetch(`${URI}/api/bootcamp/${id}`);
    const data = await response.json();

    dispatch({
      type: GET_BOOTCAMP_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BOOTCAMP_BY_ID_FAILURE,
      payload: error,
    });
  }
};

export const joinBootCampAction =
  (bootCampId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOIN_BOOTCAMP_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const response = await axios.post(
        `${URI}/api/bootcamp/join`,
        { bootCampId },
        config
      );
      const data = await response.json();

      dispatch({
        type: JOIN_BOOTCAMP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JOIN_BOOTCAMP_FAILURE,
        payload: {
          status: error?.response?.status,
          message: error?.response?.data?.error,
        },
      });
    }
  };

export const getRegisteredBootCampsAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_REGISTERED_BOOTCAMPS_REQUEST });
      let userInfo = getState()?.userLogin?.userInfo;

      // Wait for userInfo to be available
      if (!userInfo) {
        await new Promise((resolve) => {
          const unsubscribe = store.subscribe(() => {
            userInfo = getState()?.userLogin?.userInfo;
            if (userInfo) {
              unsubscribe(); // Stop listening
              resolve();
            }
          });
        });
      }
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.get(
        `${URI}/api/bootcamp/registered`,
        config
      );

      dispatch({
        type: GET_REGISTERED_BOOTCAMPS_SUCCESS,
        payload: data,
      });

      const bootcampPaymentCheck = localStorage.getItem(
        "acetechies_bootcamp_payment"
      );
      if (bootcampPaymentCheck) {
        checkPaymentStatus(data?.upcomingRegistered, bootcampPaymentCheck);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_REGISTERED_BOOTCAMPS_FAILURE,
        payload: {
          status: error?.response?.status,
          message: error?.response?.data?.error,
        },
      });
    }
  };

export const bootcampConfirmTimeSlotAction =
  (bootCampId, timeSlotId) => async (dispatch, getState) => {
    console.log("bootCampId", bootCampId);
    try {
      dispatch({ type: BOOTCAMP_CONFIRM_TIMESLOT_LOADING });
      let userInfo = getState()?.userLogin?.userInfo;

      // Wait for userInfo to be available
      if (!userInfo) {
        await new Promise((resolve) => {
          const unsubscribe = store.subscribe(() => {
            userInfo = getState()?.userLogin?.userInfo;
            if (userInfo) {
              unsubscribe(); // Stop listening
              resolve();
            }
          });
        });
      }
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.put(
        `${URI}/api/bootcamp/update-timeslot`,
        { bootCampId, timeSlotId },
        config
      );

      dispatch({
        type: BOOTCAMP_CONFIRM_TIMESLOT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: BOOTCAMP_CONFIRM_TIMESLOT_FAILED,
        payload: {
          status: error?.response?.status,
          message: error?.response?.data?.error,
        },
      });
    }
  };

export const bootcampPaymentAction =
  (merchantTransactionId, merchantUserId, amount, mobileNumber, bootcampId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BOOTCAMP_PAYMENT_INITIATION });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };

      const URI = new APIRequestURI().getBaseURI();

      const { data } = await axios.post(
        `${URI}/api/payment/phonepe/newpayment`,
        {
          merchantTransactionId,
          merchantUserId,
          amount,
          mobileNumber,
          bootcampId,
        },
        config
      );
      localStorage.setItem(
        "acetechies_bootcamp_payment",
        JSON.stringify({ isPaymentInitiated: true, bootcampId: bootcampId })
      );
      window.location.replace(data);

      dispatch({ type: BOOTCAMP_PAYMENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: BOOTCAMP_PAYMENT_FAILED, payload: error });
    }
  };

export const getMybootcampPaymentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOTCAMP_GET_MY_PAYMENTS_LOADING });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const URI = new APIRequestURI().getBaseURI();

    const { data } = await axios.get(`${URI}/api/payment/mypayments`, config);

    dispatch({ type: BOOTCAMP_GET_MY_PAYMENTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BOOTCAMP_GET_MY_PAYMENTS_FAILED, payload: error });
  }
};

export const markBootCampPaymentsViewedAction =
  () => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };

      const URI = new APIRequestURI().getBaseURI();

      await axios.put(`${URI}/api/payment/mark-viewed`, config);
    } catch (error) {
      console.log(error);
    }
  };
