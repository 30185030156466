export function convertToHoursAndMinutes(minutes) {
  if (typeof minutes !== "number" || isNaN(minutes) || minutes < 0) {
    return "Invalid input";
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes =
    remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;

  return formattedHours + ":" + formattedMinutes;
}

export const formatDate = (isoString) => {
  const date = new Date(isoString);

  // Extract day, month, and year
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-GB", { month: "short" }).format(
    date
  );
  const year = date.getFullYear();

  // Function to add ordinal suffix
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`; // Covers 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  return { day: getOrdinalSuffix(day), month, year };
};
